import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { isMobile } from 'react-device-detect';
import { url } from "../../../Utilities/url";
import { func } from "../../../Utilities/logFunc";
import Coin from '../../../assets/images/coin-icon-svg.svg'
import { useProfileDataContext } from "../ProfileContext";
// "/static/media/coin-icon-svg.9b9aef37434da26966fd5eeff0be461d.svg"
import { useTranslation } from "react-i18next";
// var stripeAPIKey = helperFunc.getStripeAPIKey()

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_URL);
const appearance = {
  theme: 'night',
  variables: {
    colorText: '#ffffff',
    // backgroundColor: '#3e4b5a79 ',
    fontFamily: 'Ideal Sans, system-ui, sans-serif',
    borderRadius: '50px',
  }
};

const PaymentModal = ({ props, showPayment, setShowPayment, coinDetails, stripeToken, subscriptionDetails, transactionUniqueID,setShowPaymentOption }) => {

const {t} = useTranslation()
const handleClose = () => {
    setShowPayment(false);
    if(setShowPaymentOption)
    setShowPaymentOption(false)
  };
  const handleSubmit = () => {
  };

  return (
    <Modal
      show={showPayment}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="authModal_transaction  modal fade "
      id="authModal_transaction"
      centered

    >
      <Modal.Body style={{ minHeight: '40rem' }}>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-12 d11-payment-modal" >
            <h3 class="text-center">{t('premium.payment_option')}</h3>

            <ul class="col-xl-10 col-lg-10 col-10 col-md-8  nav nav-pills  d-flex justify-content-between auth-nav" id="myTab" role="tablist" style={{height:'4.5rem'}}>
              <li class="nav-item" role="presentation" style={{ width: '100%', height:'100%' }}>
                {
                  !coinDetails ?
                    <button
                      style={{ width: '100%', height:'100%',backgroundColor: '#c5a260' }}
                      class="nav-link"
                      type="button"
                    >
                      <span style={{ float: 'left' }}>
                        <>
                          <img src={Coin} alt="" className="icon-24" /> {" "}
                          {subscriptionDetails?.coins}
                        </>
                      </span><span style={{ float: 'right' }}>{subscriptionDetails?.currency?.symbol} {subscriptionDetails?.amount}</span>
                    </button>
                    :
                    <button
                      style={{ width: '100%', backgroundColor: '#c5a260' }}
                      class="nav-link"
                      type="button"
                    >
                      <span style={{ float: 'left' }}>
                        <>
                          <img src={Coin} alt="" /> {" "}
                          {coinDetails?.coins}
                        </>
                      </span><span style={{ float: 'right' }}>{coinDetails?.currency?.symbol} {coinDetails?.amount}</span>
                    </button>
                }

              </li>
            </ul>
            <div class="tab-content auth-content">
              <div class="tab-pane fade show active" id="login-tab-pane" role="tabpanel" aria-labelledby="login-tab" tabindex="0" >
                {stripeToken &&
                  <Elements options={{
                    clientSecret: stripeToken,
                    appearance: appearance,
                  }} stripe={stripePromise}>
                    <MyComponent {...props} coinDetails={coinDetails} subscriptionDetails={subscriptionDetails} transactionUniqueID={transactionUniqueID} />
                  </Elements>
                }
              </div>
            </div>

          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const MyComponent = ({ props, coinDetails, subscriptionDetails, transactionUniqueID }) => {
  const stripe = useStripe();
  const elements = useElements();
  const {t} = useTranslation()

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { liteProfile} =useProfileDataContext()
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    {
      func("clenet url", url.client_base_url)
    }
    setIsLoading(true);
    let countryData = liteProfile;
    let url1 = coinDetails ? `${url.client_base_url}home/payment-status/${coinDetails._id}` : `${url.client_base_url}home/payment-status-subscription/${subscriptionDetails._id}/${transactionUniqueID}`
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: url1,
        payment_method_data: {
          billing_details: {
            name: countryData.item.gamerjiName,

            address: {
              country: countryData.country && countryData.country.name,
              state: countryData.state && countryData.state.name,
            }
          }
        },
      }


    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(error.message);
    }

    setIsLoading(false);
  };
  
  const inputStyle = {
    height:"1rem",
    iconColor: '#c4f0ff',
    color: 'red',
 
 
    fontSize: '10px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: 'red',
    },
    '::placeholder': {
      color: 'red',
    },
}

  return (
    <form id="payment-form" style={{ width: '31.25rem' }} onSubmit={handleSubmit}>

      <PaymentElement 
      id="payment-element" 
      options={{
        style: {
          base: {
            fontSize: '10px', // Adjust font size
            color: '#32325d',
            fontFamily: 'Arial, sans-serif',
          },
          inputStyle:{
            fontSize: '10px',
          }
        },
      }}
      />
      <button disabled={!stripe || !elements} id="submit" className="col-12 col-md-12 btn btn-primary mt-4" >
      {t('premium.pay_now')}
      </button>

      {/* Show any error or success messages */}
      {/* {message && <div id="payment-message">{message}</div>} */}
    </form>
  );

  // rest of the component
};

export default PaymentModal;
