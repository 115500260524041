import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from './images/compnay_logo.svg'
const LandingNavbar = ({active, setActive}) => {
  
   const navigate = useNavigate()
   const logout = () => {
      localStorage.clear();
      window.location.href = window.location.pathname
   }
  return (
    <nav class="navbar navbar-expand-lg text-white" style={{color:"#FFF",backgroundColor:'var(--color-navy-blue)'}}>
    <div class="container d-flex">
       <Link class="navbar-brand" to={`/`}><img src={Logo} class="navbar-logo" alt="" /></Link>
       <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
       <span class="navbar-toggler-icon"></span>
       </button>
       <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo02">
          <ul class="navbar-nav main-navbar-nav mb-lg-0 d-flex align-items-center">
          <li class="nav-item">
                <span class={`nav-link ${active == 5 ? "active": ""}`} aria-current="page"  style={{color:"#FFF", cursor:'pointer'}} onClick={()=> { 
                  navigate('/')
               }}>Home</span>
             </li>
            {
               // localStorage.getItem('token') ? 
               // <>
               //    <li class="nav-item ">
               //       <span class={`nav-link ${active == 3 ? "active": ""}`} style={{color:"#fff", cursor:'pointer'}} onClick={()=> {navigate('/home')}}>All Games
               //       </span>
               //    </li>
               //    <li class="nav-item ">
               //       <span class={`nav-link ${active == 3 ? "active": ""}`} style={{color:"#fff", cursor:'pointer'}} onClick={()=> {logout()}}>Logout
               //       </span>
               //    </li>
               // </>
               // :
               <li class="nav-item ">
                  <span class={`nav-link ${active == 3 ? "active": ""}`} style={{color:"#FFF", cursor:'pointer'}} onClick={()=> {
                     navigate('/login',{state:0})}}>Login</span>
               </li>
            }
          </ul>
          
       </div>
    </div>
 </nav>
  )
}

export default LandingNavbar