import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router  } from "react-router-dom";
import ThemeProvider from './ThemeContext';
import './Utilities/Translations/i18n'
import Loader from './Components/Common/Loader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Suspense fallback={<Loader />}>
    <ThemeProvider>
    <Router>
    <App />
    </Router>
    </ThemeProvider>
    </Suspense>
  // </React.StrictMode> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
