import React, { useEffect, useState } from 'react'
import { Modal, Tooltip } from 'react-bootstrap';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Cell, Label, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { getContestByGame, getGamesDetails1, getRankSummeryByGame } from '../../Utilities/controller';
import { func } from '../../Utilities/logFunc';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../Common/Loader';
import { useProfileDataContext } from '../HomePage/ProfileContext';
import { useTranslation } from "react-i18next";



const GameInsightsChart = ({ showInsights, setShowInsights, gameId, gamename }) => {
    const colors = ['#00c2ff', '#0d80a4', '#a6a6a6', '#cc4dff', '#cc9747']
    const [show, setShow] = useState(showInsights);
    const [contestByGameData, setContestByGame] = useState([]);
    const [contestRankData, setContestRankData] = useState([]);
    const [contestKillData, setContestKillData] = useState([]);
    const [contestCurrentFormData, setContestCurrentFormData] = useState([]);
    const [rankSummeryData, setRankSummeryData] = useState([]);
    const [loading, setLoading] = useState(false)
    const { profileData, updateProfileData } = useProfileDataContext()
    const {t} = useTranslation()

    const handleClose = () => {
        

        setShowInsights((prev) => false);
        setShow(false);
    };
    const fetchContestByGameData = async () => {
        try {
            let payload = {
                game: gameId,
                isShowWinLose: false,
            };
            let res = await getContestByGame(payload);
            // let res = chartdata
            const dataRank = []
            const dataKill = []
            func("contestbygame 605", res?.data)
            if (res?.data) {
                var labelsArr = [];
                var killsDataArr = [];
                var rankDataArr = [];
                var currentFormDataArr = [];

                res &&
                    res?.data?.list?.forEach((element) => {
                        //console.log({ element });
                        var jd = new Date(element.contest.date);
                        var joinedDate =
                            jd.getDate() + "/" + (jd.getMonth() + 1) + "/" + jd.getFullYear();
                        var kills = element.kills || 0;
                        var rank = element.rank || 0;
                        labelsArr.push(joinedDate);
                        killsDataArr.push(kills);
                        rankDataArr.push(rank);
                        dataRank.push({ name: joinedDate, value: rank })
                        dataKill.push({ name: joinedDate, value: kills })

                        currentFormDataArr.push({
                            joinedDate: joinedDate,
                            kills: kills,
                            rank: rank,
                            gameType: element.contest.gameType.name,
                        });
                    });
                setContestCurrentFormData(currentFormDataArr);
                let dataBar = {
                    labels: labelsArr,
                    datasets: [
                        {
                            label: "Kills Performance",
                            barThickness: 20,
                            data: killsDataArr,
                            backgroundColor: "rgba(255, 198, 9, 0.5)",
                            borderColor: "rgb(255, 198, 9)",
                        },
                    ],
                };
                setContestKillData(dataKill);
                // let dataLine = {
                //     labels: labelsArr,
                //     datasets: [
                //         {
                //             label: "Rank Performance",
                //             data: rankDataArr,
                //             fill: true,
                //             backgroundColor: "rgba(255, 198, 9, 0.2)",
                //             borderColor: "rgba(255, 198, 9,1)",
                //         },
                //     ],
                // };

                setContestRankData(dataRank);

                setContestByGame(res?.data);
                setLoading(false)
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.erros[0]?.msg)
        }
    };
    const fetchRankSummeryByGameData = async () => {

        try {
          
                const resposeGame = await getGamesDetails1(gameId)
                if(resposeGame?.data?.item?.settings?.isWinLoseShow || resposeGame?.data?.item?.settings?.isRankSummaryShow){

                
               
                func("resposeGame",resposeGame?.data?.item?.settings?.isWinLoseShow)
               
                    let payload = {
                        game: gameId,
                        isShowWinLose: resposeGame?.data?.item?.settings?.isWinLoseShow,
                        user:profileData?.item?.user_id
                    };
                      let res = await getRankSummeryByGame(payload);
                    // let res = rankSummary;
                   
                        setRankSummeryData(res?.data?.list)
                }
        }
        catch (error) {
            toast.error(error.response?.data?.errors[0]?.msg)
        }
    };
    useEffect(() => {
        func("gameId", gameId)
        setLoading(true)
        fetchContestByGameData()
        fetchRankSummeryByGameData()
    }, [])
    if (loading) return <Loader />
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="winningBreakupModal modal fade"
            centered
        >
            <Modal.Body style={{ minHeight: '400px' }}>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClose}
                ></button>
                <div class="content text-center">
                    <div className='row justify-content-center ' >
                        <div className='col-12 d-flex flex-column justify-content-center' style={{ alignItems: 'center' }}>
                            <div class="title">
                                <h3 class="text-center bodyBoldFont mb-5 pb-1">
                                    {gamename}
                                </h3>
                            </div>

                            <div class="row justify-content-between align-items-center" style={{ padding: '10px', width: '70%' }}>
                                <div class="col-3 dd-flex justify-content-center">
                                {t('Profile.date')}
                                </div>
                                <div class="col-3 d-flex justify-content-center ">
                                {t('Profile.game_type')}
                                </div>
                                <div class="col-3 d-flex justify-content-center ">
                                {t('Profile.rank')}
                                </div>
                                <div class="col-3 d-flex justify-content-center ">
                                {t('Profile.kill')}
                                </div>
                            </div>
                            {
                                contestCurrentFormData.length > 0  ? contestCurrentFormData?.map((ele, ind) => (
                                    <div className="row justify-content-center" style={{ width: '70%' }}>

                                        <div class="collegeDetailsCard ">
                                            <div class="card-body" >
                                                <ul class="list-unstyled mb-0">

                                                    <li class="d-flex justify-content-between align-items-center p-2" style={{ backgroundColor: "var(--color-black)" }}>
                                                        <div class="col-3 d-flex justify-content-center">
                                                            {/* <h5 class="mb-1 h5 text-white d-flex justify-content-center align-items-center"><span class="mx-2">
                                                               { ele?.joinedDate}
                                                            </span></h5> */}
                                                            {ele?.joinedDate}

                                                        </div>

                                                        <div class="col-3 d-flex justify-content-center ">
                                                            {/* {console.log(ele)}
                                                            <h5 class="bodyNormalFont mb-0 d-flex  align-items-center"> {ele?.gameType}</h5> */}
                                                            {ele?.gameType}
                                                        </div>

                                                        <div class="col-3 d-flex d-flex justify-content-center ">

                                                            {ele?.rank}

                                                        </div>
                                                        <div class="col-3 d-flex justify-content-center">

                                                            {/* <h6 class="text-white mx-2 my-1 " >{ele?.kills}</h6> */}
                                                            {ele?.kills}

                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                ))
                                :  t('notFound.no_records_found')
                            }

                            <div className="row justify-content-center" style={{ width: '70%' }}>
                            <p className='mt-2'>{t('Profile.kill_performance')}</p>

                                <div class="collegeDetailsCard ">
                                    <div class="card-body" >
                                        <ul class="list-unstyled mb-0">
                                            {func("contest rank data 873", contestKillData)}
                                            <li class="d-flex justify-content-between align-items-center p-2" style={{ backgroundColor: "var(--color-black)" }}>
                                                {/* <p>Kill Performace</p> */}
                                            <ResponsiveContainer width="100%" aspect={3}>
                                                <BarChart width={530} height={250} data={contestKillData}    margin={{ top: 100}}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    {/* <Legend /> */}
                                                    <Bar dataKey="value" fill="#8884d8" />
                                                </BarChart>
                                                </ResponsiveContainer>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div className="row justify-content-center" style={{ width: '70%' }}>
                            <p className='mt-2'>{t('Profile.rank_performance')}</p>

                                <div class="collegeDetailsCard ">
                                    <div class="card-body" >
                                        <ul class="list-unstyled mb-0">
                                            {func("contest rank data 873", contestRankData)}
                                            <li class="d-flex justify-content-between align-items-center p-2" style={{ backgroundColor: "var(--color-black)" }}>
                                            <ResponsiveContainer width="100%" aspect={2}>
                                                <LineChart width={530} height={250} data={contestRankData}
                                                    margin={{ top: 100}}
                                                    >
                                                    <CartesianGrid strokeDasharray="3 3" color='rgba(255, 255, 255, 0.06)' />
                                                    <XAxis dataKey="name" interval={'preserveStartEnd'} orientation='bottom'/>
                                                    <YAxis />
                                                    <Tooltip />
                                                    {/* <Legend /> */}
                                                    <Line type="monotone" dataKey="value" stroke={'#fff'} />

                                                </LineChart>
                                                </ResponsiveContainer>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-center" style={{ width: '70%' }}>
                                <p className='mt-2'>{t('Profile.rank_summary')}</p>
                                <div class="collegeDetailsCard ">
                                    <div class="card-body" >
                                        <ul class="list-unstyled mb-0">

                                            <li class="d-flex justify-content-between align-items-center p-2" style={{ backgroundColor: "var(--color-black)" }}>
                                                <ResponsiveContainer width="100%" aspect={2}>
                                                    <PieChart style={{ padding: '20px 0' }}>
                                                        <defs>
                                                            <linearGradient id="paint0_linear_2849_98932" x1="115.198" y1="0.53349" x2="0.525024" y2="112.43" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#B700FF" />
                                                                <stop offset="0.515519" stop-color="#D66DFF" />
                                                                <stop offset="1" stop-color="#EFC6FF" />
                                                            </linearGradient>
                                                            <linearGradient id="paint1_linear_2849_98932" x1="68.1733" y1="0.519531" x2="68.1733" y2="135.827" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="#FFBD59" />
                                                                <stop offset="1" stop-color="#B7A180" />
                                                            </linearGradient>

                                                        </defs>
                                                        <Legend
                                                            align='right'
                                                            layout='vertical'
                                                            verticalAlign='top'
                                                            iconType='circle'



                                                        />
                                                        {
                                                            rankSummeryData?.length > 0 ? 
                                                            <Pie

                                                            data={rankSummeryData}
                                                            // label={({ name, value }) => `${name}: ${value}`}
                                                            labelLine={false}
                                                            cx={120}
                                                            cy={120}
                                                            innerRadius="25%"
                                                            outerRadius="50%"
                                                            paddingAngle={4}
                                                        >

                                                            {
                                                                rankSummeryData?.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={colors[index]} style={{ margin: '0 10px' }} />
                                                                ))
                                                            }

                                                        </Pie>
                                                        :""
                                                        }
                                                       
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body >
        </Modal>
    )
}

export default GameInsightsChart