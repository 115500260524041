import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const QualificationModal = ({
  showQualificationModal,
  setShowQualificationModal,
  qualificationData,
}) => {
  const [show, setShow] = useState(showQualificationModal);

  const handleClose = () => {
    setShowQualificationModal((prev) => false);
    setShow(false);
  };
  const {t} = useTranslation()
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="row justify-content-center">
          <div class="col-11">
            <div class="title">
              <h3 class="text-center bodyBoldFont mb-5 pb-1">{t('contest_details.qualification')}</h3>
            </div>
            <div class="text-center">
              <div class="row">
                <div class="col-6 text-start">
                  <h4 class="bodyBoldFont mb-0">
                    Rank 1 - {qualificationData?.winnerCount || 0}
                  </h4>
                </div>
                <div class="col-6 text-end">
                  <h4 class="bodyBoldFont mb-0">{t('contest_details.qualified_for_next_round')}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QualificationModal;
