import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router";
import TournamentModal from "./TournamentModal";
import TournamentConfirmationModal from "./TournamentConfirmationModal";
import { func } from "../../../Utilities/logFunc";
import TournamentStructure from "./TournamentStructure";
import WinningBreakUp from "./WinningBreakUp";
import NotFound from "../../../assets/images/noContestFound.png";
import NoTournamentFound from "../../../assets/images/noTournamentFound.png";

import {
  getContest,
  getTournament,
  requestWalletUsageLimit,
  walletUsageLimit,
  getUserInGameName,
  getGameTypeLists,
  checkSubscriptionStatus
} from "../../../Utilities/controller";
import Banner from "../Contest/Banner";
import GameTypeTab from "../Contest/GameTypeTab";
import RulesModal from "./RulesModal";
import ContestStructure from "../Contest/ContestStructure";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContestConfirmationModel from "../Contest/ContestConfirmationModel";
import Loader from "../../Common/Loader";
import TeamRegisterModal from "./TeamRegisterModal";
import BasicProfile from "./BasicProfile";
import BasicProfileContest from "./BasicProfileContest";
import BasicProfilePrivateContest from "./BasicProfilePrivateContest";
import { getGameTypeList } from "../../../Utilities/controller";
import ShowFreeContestModal from "../../Subscription/ShowFreeContestModal";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import Pagination from "../../Common/Pagination";
import { events } from "../../../Utilities/appEvents";
import { eventTracking } from "../../../firebaseAnalytics";

const GameTournament = () => {
  var user_data = JSON.parse(localStorage.getItem("profile"))?.item;
  const gameId = localStorage.getItem("activeGameId");
  const gameName = localStorage.getItem("activeGame");
  const bannerImage = localStorage.getItem("activeGameBannerImage");
  const { profileData, updateProfileData,liteProfile } = useProfileDataContext()

  const {t} = useTranslation()  

  const navigate = useNavigate();
  
  // const [userDataInfo, setUserData] = useState("");

  const [showTournamentModal, setShowTournamentModal] = useState(false);
  const [showTournamentConfirmationModal, setShowTournamentConfirmationModal] =
    useState(false);
    let premiumUser = JSON.parse(localStorage.getItem("premiumUser"));

  const [selectedMatch, setMatch] = useState(null);

  const [showWinningModal, setShowWinningModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [showRulesModal, setShowRulesModal] = useState(false);

  const [showTeamRegisterModal, setShowTeamRegisterModal] = useState(false);
  const [showBasicProfileModal, setShowBasicProfileModal] = useState(false);
  const [showBasicProfileContestModal, setShowBasicProfileContestModal] =
    useState(false);
  const [showPremiumPopupContest, setShowPremiumPopupContest] = useState(false);
  const [showPremiumPopupTournament, setShowPremiumPopupTournament] =
    useState(false);

  const [
    showBasicProfilePrivateContestModal,
    setShowBasicProfilePrivateContestModal,
  ] = useState(false);

  const [contests, setContests] = useState([]);
  const [active, setActive] = useState(0);
  const [gameType, setGameType] = useState([]);
  const [activeId, setActiveId] = useState(0);
  const [gameTypeLength, setGameTypeLength] = useState(0);

  const [selected, setselected] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [activeTournament, setActiveTournament] = useState(null);
  const [activeCurrencyWallet, setActiveCurrencyWallet] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [showJoinModal, setShowJoinModal] = useState(null);
  const [userInGameName, setUserInGameName] = useState("");
  const [showContestConfirmationModal, setShowContestConfirmationModal] =
    useState(false);

  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  const [currData, setCurrData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [limitNo, setLimitNo] = useState(6);
  const [totalPagesNo, settotalPagesNo] = useState(0);
  const [totalRecordNo, settotalRecordNo] = useState(0);
  const [totalContest, setTotalContest] = useState(0);
  const [contestPage, setContestPage] = useState(1);

  const handlerJoin = async (data) => {
    setLoading(true);
    const res = await checkSubscriptionStatus();
    if(!premiumUser || res?.data?.status === 1 || res?.data?.status === 2){
      setShowPremiumPopupTournament(true)
      setShowPremiumPopupContest(true)
      setLoading(false);
    }else{
   
    setActiveTournament(data);
    setSelectedTournament(data);
    // localStorage.setItem("activeGameId", data?.gameAry?.[0]?._id);
    // localStorage.setItem("activeGame", data?.gameAry?.[0]?.name);
    const payload = {
      search: {
        user: profileData?.item?.user_id || user_data?.user_id,
        game: data?.gameAry?.[0]?._id,
      },
    };
    try {

      eventTracking(events.tournament_join_now, {
        OP_GameName: data?.gameAry?.[0]?.name,OP_GameID: data?.gameAry?.[0]?._id,OP_TournamentID: data?._id,OP_TournamentDate: data?.startDate, OP_TournamentTime: data?.startDate
      });
      getWallet(data);
      // const isName = await getUserInGameName(payload);
    //  const isName =  getUserInGameNameData(data?.gameAry?.[0]?._id);
    let isName = false
    if(data?.userInGameName){
      isName = true
      setUserInGameName(data?.userInGameName)
    }
    else 
     isName = await getUserInGameName(payload);
      if (isName) {
       

       
        // if (userInGameName) {
        setShowTournamentModal(true);
        // } else {
        //   setShowBasicProfileModal(true);
        // }
        setLoading(false);
      }
      else{
        setShowBasicProfileModal(true);
      }
    } catch (error) {
      setLoading(false);
      func("getWalletUsageLimit error", error?.response?.data);
      if (
        error?.response?.data?.errors?.[0]?.code == "users.validation.not_found"
      )
        setShowBasicProfileModal(true);
      else toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  }
  };

  const getUserInGameNameData = async (gameType) => {
    setLoading(true);
    try {
      let data = {
        search: {
          user: user_data.user_id,
          game: localStorage.getItem("activeGameId"),
        },
      };

      const res = await getUserInGameName(data);
      if (res?.data?.data) {
        console.log(res?.data?.data)
        let index = res?.data?.data.findIndex(
          (obj) => obj.game == gameType?.game?._id
        );

        if (index > -1) {
          setUserInGameName(res?.data?.data[index].userINGameName || "");
        }
        // localStorage.setItem(
        //   "activeInNameGame",
        //   res?.data?.data?.[0]?.userINGameName
        // );
        setLoading(false);
        return true;
      } else {
        return false;
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // if(error?.response?.data?.errors?.[0]?.code == "users.validation.not_found")
      // toast.error(error?.response?.data?.errors?.[0]?.msg )
      // toast.error(error?.response?.data?.errors?.[0]?.msg )

      return false;
    }
  };

// console.log("profileData?.item?.user_id ", profileData?.item);
  const handleJoinContest = async (item) => {
    setLoading(true);
    const res = await checkSubscriptionStatus();
    
    if (premiumUser && res?.data?.status === 0) {
      setselected(item);
    let payload = {
      contest: item?._id,
      currencyCode: item?.currency[0]?.inCurrency?._id,
      entryFee: item?.entryFee,
      type: "contest",
    };
    try {
      const res = await requestWalletUsageLimit(payload);
      setWalletData(res?.item);
      // localStorage.setItem("activeGameId", item?.game);
      // localStorage.setItem("activeGame", item?.gameType);
      const data = {
        search: {
          user: profileData?.item?.user_id || user_data?.user_id,
          game: item?.gameAry?.[0]?._id,
        },
      };
      // console.log(item?.userInGameName)
      let isName = false
      if(item?.userInGameName){
        isName = true
        setUserInGameName(item?.userInGameName)
      }
      else 
       isName = await getUserInGameName(data);
      // console.log(isName);
     
      // setLoading(true);
    
      if (isName) {
        
        if (res?.success && res?.item) {
          // console.log("*********", res)
         
          setTimeout(async function () {
            if (item?.isJoined) {
              navigate("/home/contest-details/" + item._id);
            } else {
              setShowContestConfirmationModal(true);
            }
            // else {
            //   if (userInGameName) {
            //     setShowContestConfirmationModal(true);
            //   } else {
            //     setShowBasicProfileContestModal(true);
            //   }
            // }
          }, 500);
          setLoading(false);
        } else {
          setLoading(false);
          func("getWalletUsageLimit err");
        }
      } else {
        setLoading(false);
        setShowBasicProfileContestModal(true);
      }
    } catch (error) {
      setLoading(false);
      func("getWalletUsageLimit error", error?.response?.data);
      if (
        error?.response?.data?.errors?.[0]?.code == "users.validation.not_found"
      )
        setShowBasicProfileContestModal(true);
      else toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
    }else{
      setShowPremiumPopupContest(true);
      setLoading(false);
    }
    
  };

  const handlerActive = (ind) => {
    setActive(ind);
    func("data168", ind);
    // setCurrData(data[ind])
  };

  const handlerGameTypeLength = (type) => {
    setGameTypeLength(type);
  };

  const handlerWinning = (data) => {
    setSelectedTournament(data);
    eventTracking(events.tournamnet_winners, {
      OP_GameName: localStorage.getItem("activeGame"),
      OP_GameID: localStorage.getItem("activeGameId"),
      OP_ContestID: data._id,
      OP_GameTypeID:data.gameTypeAry._id,
    });
    setShowWinningModal((prev) => true);
  };

  const handleClickGameType = (name, id) => {
    // func("handleClickGameType", name, id);
    // setActive(name);
    // setActiveId((prev) => id);
  };

  const getTournamentList = async (page) => {
    let limit=limitNo
    let page1 = page || 0
    setPageNo(page1)
    try {
      let data = {
        skip: page1>0?page1*limit:0,
        limit: limit,
        filter: {
          tournamentType: "gamerji",
          user: profileData?.item?.user_id || user_data?.user_id,
          game: gameId,
        },
      };
      setLoading(true);
      console.log("Payload^^^^^^^^^^limit",data?.limit,"^^^^skip^^^",data?.skip,"^^^^page^^^",page1)
      const res = await getTournament(data);
      console.log("response^^^^^^^^^^limit",res.data.totalRecord,res.data.totalPages)
      if (res?.data?.data) {
        settotalPagesNo(res.data.totalPages)
        settotalRecordNo(res.data.totalRecord)
        setTournaments(res?.data?.data);
      }
      setLoading(false);

      // func(res);
    } catch (error) {
      func("getTournament error", error?.response?.data?.errors?.[0]?.msg);
      toast(error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  const getGameTypeByGameList = async () => {
    try {
      let data = {
        search: {
          gameId: gameId,
        },
        pagination: {
          sortby: "order",
          sort: "asc",
        },
      };

      const res = await getGameTypeLists(data);
      if (res?.data?.data) {
        var tempGameTypes = [];

        tempGameTypes.push(...res?.data.data);

        setGameType(tempGameTypes);
        // setActiveId(res?.data?.data[0]._id);
        // setTypeLength(res?.data?.data?.length)
        // setActive(res?.data?.data?.length)
        func("tempTypes", tempGameTypes);
        handlerGameTypeLength(res?.data?.data?.length || 0);

        func("game type 257", tempGameTypes[0]._id);
        getContestList(tempGameTypes[0], contestPage);
        // setActive(r);
        // getTournamentList();
        setLoading(false);
      } else {
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.data ==
        "events.validation.MAX_FREE_TRANSACTION"
      ) {
        setShowPremiumPopupContest(true);
      } else {
        toast.error(error?.response?.data?.errors?.[0]?.msg);
        setLoading(false);
      }

      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    setLoading(true);
    getGameTypeByGameList();
    getUserInGameNameData();
    // setUserData(liteProfile);

    // getContestList();
    // getTournamentList();
  }, []);

  const getWallet = async (dataItem) => {
    try {
      let data = {
        event: dataItem?._id,
        type: "event",
        currencyCode: dataItem?.currency?.[0]?.inCurrency?._id,
        entryFee: dataItem?.entryFee,
      };

      const res = await walletUsageLimit(data);
      if (res?.data?.item) {
        setActiveCurrencyWallet(res?.data?.item);
      }
    } catch (error) {
      func("getTournament error", error?.response?.data?.errors?.[0]?.msg);
      toast.error(error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };



  const getContestList = async (data, page) => {
    setLoading(true);
    func("active", active);
    // console.log(profileData?.item?.user_id, user_data?.user_id)
    try {
      func("data 290", data);
      let dataTemp = [];

      // for (let i = 0; i < data.length; i++) {
      let payload = {
        search: {
          user: profileData?.item?.user_id || user_data?.user_id,
          game: gameId,
          gameType: data?._id,
        },
        pagination: {
          pageNo: page,
          recordPerPage: 6,
          sortBy: "dateTime",
          sortDirection: "asc",
        },
      };
      // setLoading(true);
      const res = await getContest(payload);

      // }
      func("contestttttttt Data", res?.data);
      setData(res?.data);
      setTotalContest(res.totalPages)
      setLoading(false);
    } catch (error) {
      func("getContest error", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   // console.log("gameTypeLength", gameTypeLength);
  //   // console.log("gameTypeLength active", active);
  //   getAccountProfileDetails();
  // }, []);

  useEffect(() => {
    console.log("game type 350", active, gameType?.length, active === gameType?.length );
    if(gameType?.length > 0){
      if (active < gameType?.length)  {
        let totalCount = 1;
        setContestPage(totalCount);
        getContestList(gameType[active], totalCount);
      }
      else
      getTournamentList(pageNo)
    }
   
  }, [setActive, active]);

  const handlePageChange = (page) => {
    if(gameType?.length > 0){
      if (active < gameType?.length)  {
        setContestPage(page);
        getContestList(gameType[active], page);
      }
      else
      getTournamentList(pageNo)
    }
  };

  useEffect(() => {}, [showTournamentConfirmationModal]);

  // if (isLoading) return <Loader />;
  return (
    <>
      {/* <ToastContainer /> */}
      {isLoading && <Loader />}
      <div className="content">
        <div className="content-inner">
          <div className="row gy-4">
            <Banner bannerImage={bannerImage} />

            <div className="col-12 mt-5 pt-lg-4 commonTab tournamentTypeTab">
              {/* <GameTypeTab
              handleClickGameType={handleClickGameType}
              gameId={localStorage.getItem("activeGameId")}
              setGameTypeLength={setGameTypeLength}
              // setActiveTab={setActive}
              gameType={gameType}
              handlerActive={handlerActive}
            /> */}
              <ul
                className="nav nav-tabs row"
                id="tournamentTypeTab"
                role="tablist"
              >
                {gameType.map((item, ind) => (
                  <li
                    className="nav-item col d-grid"
                    role="presentation"
                    onClick={() => {
                      handlerActive(ind);
                      setActive(ind);
                      // handlerActive(item, ind)
                    }}
                    key={ind}
                  >
                    <button
                      className={`nav-link ${ind == active ? "active" : ""}`}
                      role="tab"
                      aria-controls="solo-tab-pane"
                      aria-selected="true"
                    >
                      {item?.name}
                    </button>
                  </li>
                ))}
                <li
                  className="nav-item col d-grid"
                  role="presentation"
                  onClick={() => handlerActive(gameType?.length)}
                  // key={typeLength}
                >
                  <button
                    className={`nav-link ${
                      active == gameType?.length ? "active" : ""
                    }`}
                    role="tab"
                    aria-controls="solo-tab-pane"
                    aria-selected="true"
                    onClick={()=>{
                      
                      eventTracking(events.tournaments, {
                        OP_GameName: localStorage.getItem('activeGame'),
                         OP_GameID:  localStorage.getItem('activeGameId'),
                      });
                
                    }}
                  >
                    {t('header.tournaments')}
                  </button>
                </li>
              </ul>
              {func("contest list", active)}
              <div className="tab-content" id="tournamentTypeTabContent">
                {active == gameType?.length ? (
                  tournaments.length > 0 ? (
                    <>
                    <div className="row gx-3 gy-5">
                      {tournaments?.map((item, ind) => {
                        if(item){
                          return (
                            <div
                              className="col-lg-4"
                              style={{ cursor: "pointer" }}
                              key={ind}
                            >
                              <TournamentStructure
                                handlerJoin={() => handlerJoin(item)}
                                handlerWinning={() => handlerWinning(item)}
                                tournaments={item}
                                setShowRulesModal={setShowRulesModal}
                                setSelectedTournament={setSelectedTournament}
                                setShowJoinModal={setShowJoinModal}
                              />
                            </div>
                          );
                        }
                        
                      })}
                    </div>
                    {
                      totalRecordNo > limitNo && 
                      <Pagination
                        currentPage={pageNo + 1}
                        totalPages={totalRecordNo}
                        onPageChange={(page) => getTournamentList(page - 1)}
                      />
                      // <div className="pagination-div">
                      //   <div className="previous-page" onClick={()=>{pageNo!==0?getTournamentList(pageNo>=1?pageNo-1:0):console.log("FIRSTPAGE")}}>
                      //   <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 0 22 22" width="42"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/></svg>
                      //   </div>
                      //   <span className="page-count">
                      //   {pageNo>0?(pageNo*limitNo)+1:1}-{pageNo*limitNo+(tournaments?.length||0)} of {totalRecordNo}</span>
                      //   <div className="next-page"  onClick={()=>{(totalRecordNo!==pageNo*limitNo+(tournaments?.length||0))? getTournamentList(pageNo<totalPagesNo?pageNo+1:totalPagesNo):console.log("LASTPAGE")}}>
                      //   <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 0 22 22" width="42"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/></svg>
                      //   </div>
                      // </div>  
                    }
                    </>
                  ) : (
                    <div class="row justify-content-center">
                    <div className="col-lg-5">
                      <img
                        className="img-fluid winningBreakupModal"
                        src={NoTournamentFound}
                        alt=""
                      />
                    </div>
                  </div>
                  )
                ) : data?.length > 0 ? (
                  <>
                  <div className="row gx-3 gy-5 mb-5">
                    {data?.map((item) => {
                      return (
                        <div className="col-lg-4 col-md-6" key={item?._id}>
                          <ContestStructure
                            handleJoinContest={() => handleJoinContest(item)}
                            handlerWinning={handlerWinning}
                            tournaments={item}
                            setselected={setselected}
                          />
                        </div>
                      );
                    })}
                    {/* {contestPage < totalContest && (
                      <div class="d-flex justify-content-center mt-5">
                        <button
                          class="btn btn-primary col-3 text-center mt-5"
                          onClick={() => {
                            let totalCount = parseInt(contestPage) + 1;
                            setContestPage(totalCount);
                            getContestList(gameType[active], totalCount)
                            
                          }}
                        >
                          Load More
                        </button>
                      </div>
                    )} */}
                  </div>
                  <div className="row gx-3 gy-5 mt-5">
                    {totalContest > 6  && (
                      <Pagination
                        currentPage={contestPage}
                        totalPages={totalContest}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                  </>
                ) : (
                  <div class="row justify-content-center">
                  <div class="col-lg-5">
                    {func("active 327", active)}
                    <img
                    className="img-fluid winningBreakupModal"
                      src={
                        active == "tournament" ? NoTournamentFound : NotFound
                      }
                      alt=""
                    />
                  </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showTournamentModal && (
        <TournamentModal
          showTournamentModal={showTournamentModal}
          setShowTournamentModal={setShowTournamentModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
          setMatch={setMatch}
          tournaments={activeTournament}
          userInGameName={userInGameName}

        />
      )}

      {showTournamentConfirmationModal && (
        <TournamentConfirmationModal
          showTournamentConfirmationModal={showTournamentConfirmationModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
          tournaments={selectedTournament}
          walletData={activeCurrencyWallet}
          match={selectedMatch}
          userInGameName={
            userInGameName || localStorage.getItem("activeInNameGame")
          }
          setShowPremiumPopupTournament={setShowPremiumPopupTournament}
        />
      )}
      {func("user name contest 512", userInGameName)}
      {showContestConfirmationModal && (
        <ContestConfirmationModel
          showContestConfirmationModal={showContestConfirmationModal}
          setShowContestConfirmationModal={setShowContestConfirmationModal}
          tournaments={selected}
          walletData={walletData}
          userInGameName={
            userInGameName || localStorage.getItem("activeInNameGame")
          }
          setShowPremiumPopupContest={setShowPremiumPopupContest}
        />
      )}

      {showWinningModal && (
        <WinningBreakUp
          showWinningModal={showWinningModal}
          setShowWinningModal={setShowWinningModal}
          selectedTournament={selectedTournament}
        />
      )}
      {showRulesModal && (
        <RulesModal
          showRulesModal={showRulesModal}
          setShowRulesModal={setShowRulesModal}
          selectedTournament={selectedTournament}
        />
      )}

      {showTeamRegisterModal && (
        <TeamRegisterModal
          showTeamRegisterModal={showTeamRegisterModal}
          setShowTeamRegisterModal={setShowTeamRegisterModal}
          setShowPremiumPopupTournament={setShowPremiumPopupTournament}
        />
      )}

      {showBasicProfileModal && (
        <BasicProfile
          showBasicProfileModal={showBasicProfileModal}
          setShowBasicProfileModal={setShowBasicProfileModal}
          game={localStorage.getItem("activeGameId")}
          setShowTournamentModal={setShowTournamentModal}
          setUserInGameName={setUserInGameName}
        />
      )}

      {showBasicProfileContestModal && (
        <BasicProfileContest
          showBasicProfileModal={showBasicProfileContestModal}
          setShowBasicProfileModal={setShowBasicProfileContestModal}
          game={localStorage.getItem("activeGameId")}
          setShowContestConfirmationModal={setShowContestConfirmationModal}
          setUserInGameName={setUserInGameName}
        />
      )}
      {showPremiumPopupContest && (
        <ShowFreeContestModal
          title={"contest"}
          contest={selected}
          showPremiumPopupContest={showPremiumPopupContest}
          setShowPremiumPopupContest={setShowPremiumPopupContest}
        />
      )}
      {true && (
        <ShowFreeContestModal
          title={"tournament"}
          tournaments={selectedTournament}
          showPremiumPopupContest={showPremiumPopupTournament}
          setShowPremiumPopupContest={setShowPremiumPopupTournament}
        />
      )}
    </>
  );
};

export default GameTournament;
