import React, { useEffect, useState } from "react";
import {
  userRequestOTP,
} from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import { useLocation, useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";
import { useTranslation } from "react-i18next";
import { decryptKey } from "../../Utilities/encryptDecrypt";
import { useSearchParams } from "react-router-dom";
import logo from "../../assets/images/white_logo.png"
import group from "../../assets/images/Group2.png"
import oops from "../../assets/images/error_logo.png"

const Login = () => {
  func("login", "login page");
  const [searchParams] = useSearchParams()
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  // const [error, setError] = useState("");
  const [msisdn, setMsisdn] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const location = useLocation()
  const {state} = location
  const { t } = useTranslation();


  //CODE BLOCK START FOR AUTO LOG IF IN LOGIN PAGE HAS MSISDN NO IN PARAMS
  useEffect(() => {
    if(searchParams.get("token")){
      setLoader(true)
      let token= location.search.split('token=')
      setMsisdn(token[1])
    }
  }, [searchParams]);
  useEffect(() => {
    if(msisdn){
      callAutoLoging(msisdn)
    }
  }, [msisdn]);
  const callAutoLoging = async (msisdn) => {
    var payload = {
      type: "autoLogin",
      msisdn: msisdn,
    };
    try {
      const res = await userRequestOTP(payload);
        setLoading(false);
        localStorage.setItem("user-id", res?.item?.id);
        localStorage.setItem("token", res?.token);
        localStorage.setItem(
          "d11_username",
          res?.item?.gamerjiName
        );
        if (res?.item?.isDetailsFilled) window.location.href = "/" + routes.home;
        else window.location.href = "/avatar";
    } catch (error) {
      setLoading(false);
    }
    
  };

  const onClickTryAgain = async(e) => {
    const msidn = await decryptKey(searchParams.get("token"));
    window.location.href = "http://ooqa.peerlinear.tech/ps/oredo/msisdn=" + msidn
  } 

  return (
    <>
      {/* {loader ? (
        <Loader />
      ) : ( */}
        <div className="error_main">
          <div className="error_background">
            <div className="error_logo">
              <img src={logo} alt="" />
            </div>
            <div className="error_games">
              <img src={group} alt="" />
            </div>
            <div style={{height: '190px'}}>

            </div>
            {
              !isLoading ? 
                <>
                  <div className="error_oops">
                    <img src={oops} alt="" />
                  </div>
                  <div className="error_message">
                    We are unable to process your request <br/> Please click below to try again
                  </div>
                  <div className="error_button_main">
                    <button className="btn error_button" onClick={(e) => onClickTryAgain()}>Try Again</button>
                  </div>
                </>
                :
                <div className="error_message">
                    Authentication In Progress.....
                </div>
            }
          </div>
        </div>
        {/* <LoginLandingPage>
          <BaseAuth>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-sm-9">
               
              </div>
            </div>
          </BaseAuth>
        </LoginLandingPage>
      )} */}
    </>
  );
};
export default Login;
