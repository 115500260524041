import React, { useState, useEffect } from "react";

import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { json, useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import { func } from "../../../Utilities/logFunc";
import {
  getAccountProfile,
  getCountryList,
  getUserInGameName,
  onCreateScrim,
} from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";
import { errors } from "../../../Utilities/error";
import Error from "../../Common/Error";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";

const TeamRegisterScrim = ({
  showTeamRegisterScrimModal,
  setShowTeamRegisterScrimModal,
  noOfPlayer,
  game,
  scrimId,
  userInGameName,
  setShowTournamentConfirmationModal,
  setShowJVC
}) => {
  console.log(userInGameName)
  const [countryList, setCountryList] = useState([]);
  const [userDataInfo, setUserData] = useState("");
  const [teamName, setTeamName] = useState("");
  const [captainInfo, setCaptainInfo] = useState({
    gamerjiName: "",
    countryCode: "",
    phoneNo: "",
    userInGameName:""
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(showTeamRegisterScrimModal);
  const handleClose = () => {
    setShowTeamRegisterScrimModal((prev) => false);
    setShow(false);
    if(setShowJVC) setShowJVC(false)
  };
  let newArray = [];
  const [isLoading, setLoading] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [err, setErr] = useState("");
  const { liteProfile, profileData } = useProfileDataContext();

  for (let i = 0; i < noOfPlayer - 1; i++) {
    newArray.push({
      type: "phoneNo",
      countryCode: "",
      phoneNo: "",
      inNameGame: "",
      gamerjiName: "",
      isSubmit: false,
      isOptional: false,
      userId: "",
      levelImage: "",
    });
  }

  newArray.push({
    type: "phoneNo",
    countryCode: "",
    phoneNo: "",
    inNameGame: "",
    gamerjiName: "",
    isSubmit: false,
    isOptional: true,
    userId: "",
    levelImage: "",
  });

  const [frmInitialValues, setFrmInitialValues] = useState(newArray);

  const fetchCountry = async () => {
    try {
      const res = await getCountryList();
      setCountryList((prev) => [...res?.list]);
    } catch (error) {
      func("error", error);
    }
  };

  const getUserInGameNameData = async (data, index) => {
    try {
      const res = await getUserInGameName(data);
      if (res?.data?.data) {
        let formdata = [...frmInitialValues];
        let fromdataobj = { ...formdata[index] };
        fromdataobj.inNameGame = res?.data?.data?.[0]?.userINGameName;
        fromdataobj.gamerjiName = res?.data?.data?.[0]?.gamerjiName;
        fromdataobj.isSubmit = true;
        fromdataobj.userId = res?.data?.data?.[0]?.user;
        fromdataobj.userLevel =
          res?.data?.data?.[0]?.userLevel?.level?.featuredImage?.default;
        formdata[index] = fromdataobj;
        setFrmInitialValues(formdata);
      }
      func("error", res);
    } catch (error) {
      toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    fetchCountry();
    let userData = liteProfile;
    setUserData(userData);
  }, []);

  useEffect(() => {
    let formdata = { ...captainInfo };
    formdata.gamerjiName = userDataInfo?.item?.gamerjiName;
    formdata.phoneNo = userDataInfo?.item?.phone;
    formdata.userInGameName = userInGameName?.[0]?.userINGameName
    // let newArray = countryList.filter(function (el) {
    //   return el?.dialingCode === userDataInfo?.item?.phoneCode;
    // });

    // if (newArray?.length && newArray?.length > 0) {
    //   formdata.countryCode = newArray[0]?.dialingCode;
    // }
    formdata.countryCode = userDataInfo?.item?.phoneCode;
    func("captain data******************", formdata);
    setCaptainInfo(formdata);

    let playerArray = [...frmInitialValues];
    let newArrayInfo = [];
    for (let i = 0; i < playerArray.length; i++) {
      playerArray[i].countryCode = formdata.countryCode;
      newArrayInfo.push(playerArray[i]);
    }
    setFrmInitialValues(newArrayInfo);
  }, [userDataInfo]);

  const handleUsrBtn = async (index) => {
    setErr("");
    let formdata = [...frmInitialValues];
    let fromdataobj = { ...formdata[index] };
    func("datatatattata", fromdataobj);
    if (fromdataobj?.phoneNo == "") {
      setErr(t("error_Please_enter_the_phone_number"));
      return;
    }
    if (captainInfo?.phoneNo == fromdataobj?.phoneNo) {
      setErr(t("error_enter_other_than_yours"));
      return;
    }
    let checkTeam = checkMobileWithTeam(fromdataobj?.phoneNo, index);
    func("checkTeam", checkTeam);
    if (!checkTeam) {
      let data = {
        search: {
          phone: fromdataobj?.phoneNo,
          phoneCode: fromdataobj?.countryCode,
          game: game,
        },
      };
      getUserInGameNameData(data, index);
    } else {
      setErr(t("error_enter_already_added"));
      return;
    }
  };

  let checkMobileWithTeam = (number, ind) => {
    let index = frmInitialValues.findIndex(
      (obj, i) => obj?.phoneNo == number && i !== ind
    );
    let indexCap = frmInitialValues.findIndex(
      (obj) => obj?.phoneNo == captainInfo.phoneNo
    );
    if (index > -1 || indexCap > -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleUsrBtnRemove = async (index) => {
    let formdata = [...frmInitialValues];
    let fromdataobj = { ...formdata[index] };
    fromdataobj.inNameGame = "";
    fromdataobj.gamerjiName = "";
    fromdataobj.userId = "";
    fromdataobj.userLevel = "";
    fromdataobj.phoneNo = "";
    fromdataobj.phoneNo = "";
    fromdataobj.isSubmit = false;
    formdata[index] = fromdataobj;
    setFrmInitialValues(formdata);
    setErr("");
  };

  const handleSubmit = async () => {
    let errMsg = "";

    if (!teamName) {
      errMsg += `Please enter team name`;
      toast.error(`Please enter team name`);
      return;
    }

    frmInitialValues.forEach((euser, index) => {
      index = index + 1;
      if (!euser?.isOptional) {
        if (!euser.phoneNo) {
          errMsg += `${t("error_Please_enter_the_phone_number")} ${index + 1}`;
          toast.error(
            `${t("error_Please_enter_the_phone_number")} ${index + 1}`
          );
          return;
        }
        if (!euser.isSubmit) {
          errMsg += `Please add user ${index + 1}`;
          toast.error(`Please add user ${index + 1}`);
          return;
        }
        if (!euser.gamerjiName) {
          errMsg += `${t("error_enter_gamerji_username")} ${index + 1}`;
          toast.error(`${t("error_enter_gamerji_username")} ${index + 1}`);
          return;
        }
        if (!euser.inNameGame) {
          errMsg += `${t("success_Please_enter_Gamerji_username")} ${
            index + 1
          }`;
          toast.error(
            `${t("success_Please_enter_Gamerji_username")} ${index + 1}`
          );
          return;
        }
      } else {
        if (euser.phoneNo != "") {
          if (!euser.phoneNo) {
            errMsg += `${t("error_Please_enter_the_phone_number")} ${
              index + 1
            }`;
            toast.error(
              `${t("error_Please_enter_the_phone_number")} ${index + 1}`
            );
            return;
          }
          if (!euser.isSubmit) {
            errMsg += `Please add user ${index + 1}`;
            toast.error(`Please add user ${index + 1}`);
            return;
          }
          if (!euser.gamerjiName) {
            errMsg += `${t("error_enter_gamerji_username")} ${index + 1}`;
            toast.error(`${t("error_enter_gamerji_username")} ${index + 1}`);
            return;
          }
          if (!euser.inNameGame) {
            errMsg += `${t("success_Please_enter_Gamerji_username")} ${
              index + 1
            }`;
            toast.error(
              `${t("success_Please_enter_Gamerji_username")} ${index + 1}`
            );
            return;
          }
        }
      }
    });

    if (errMsg) {
      return false;
    } else {
      let players = [];
      func("frmInitialValues", frmInitialValues);
      frmInitialValues.forEach((euser, index) => {
        if (euser?.phoneNo) {
          let country_id = "";
          let newArray = countryList.filter(function (el) {
            return el?.dialingCode === euser?.countryCode;
          });

          if (newArray?.length && newArray?.length > 0) {
            country_id = newArray[0]?._id;
          }

          players.push({
            mobileNo: euser?.phoneNo,

            inGameName: euser?.inNameGame,
            userId: euser?.userId,
            gamerJiName: euser?.gamerjiName,
            email: euser?.email ? euser?.email : euser?.phoneNo,
            country: liteProfile?.country?.name,
          });
        }
      });
      // func("players", JSON.parse(localStorage.getItem("userData")));

      // const user_data = JSON.parse(localStorage.getItem("userData"));
      func("players", captainInfo);
      let data = {
        scrimId: scrimId,
        userId: profileData?.item?.user_id,
        usersInGameName: captainInfo?.gamerjiName,
        gamerJiName: captainInfo.gamerjiName,
        team: players,
        teamName: teamName,
        mobileNo: captainInfo?.phoneNo,
      };
      func("payload", data);
      setLoading(true);

      try {
        let res = await onCreateScrim(data);
        // console.log(res)
        if (res?.data?.success) {
          setLoading(false);
          // setShowTournamentConfirmationModal( false);
          // setShowTeamRegisterScrimModal((prev) => false);
          toast.success(res?.data?.data?.[0]?.msg);
          setShowTeamRegisterScrimModal(false);
          // setShowTeamRegisterScrimModal(false);
          navigate("/home/scrim-details/" + scrimId);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        // toast.error(error?.response?.data?.errors[0]?.msg)
        // func("error", error?.response?.data?.errors[0]?.msg)

        toast.error(error?.response?.data?.errors?.[0]?.msg);
      }
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="tournamentModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="row justify-content-center">
          <div class="col-lg-11 col-12 px-lg-4">
            <div class="title">
              <h3 class="text-center bodyBoldFont mb-4 pb-3">
                {t("team_register.team_registeration")}
              </h3>
            </div>
            <div class="content">
              <div class="row justify-content-center mt-4 pt-2">
                <div class="col-lg-7 col-md-9 col-12 px-lg-2">
                  <Formik
                    enableReinitialize={true}
                    initialValues={frmInitialValues}
                    // onSubmit={(values) => {
                    // }}
                  >
                    {({ errors, values }) => (
                      <form>
                        <div class="mb-4">
                          <label
                            for="exampleInputEmail1"
                            class="form-label h5 mb-1"
                          >
                            {t("team_register.team_name")}
                          </label>
                          <input
                            type="email"
                            class="form-control form-control-sm"
                            id="exampleInputEmail1"
                            placeholder={t("team_register.enter_team_name")}
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                          />
                        </div>
                        <div class="card addPlayerCard mb-4">
                          <div
                            class="card-header d-flex align-items-center justify-content-between"
                            style={{ position: "relative" }}
                          >
                            <div class="playerDesignation">
                              {" "}
                              {t("team_register.captain")}
                            </div>
                            <h4
                              class="bodyBoldFont mb-0 px-10"
                              // style={{width:'50%',position:'absolute',right:0}}
                            >
                              {captainInfo?.gamerjiName}
                            </h4>
                          </div>
                          <div class="card-body">
                            <div class="row align-items-center mb-2">
                              <div class="col-5">
                                <div class="input-group w-auto">
                                  <span
                                    class="input-group-text rounded-start-5 bg-dark border-0 pe-0"
                                    id="basic-addon1"
                                  >
                                    <img
                                      className="icon-24"
                                      src={
                                        url.imageUrl +
                                        liteProfile?.country?.flag?.default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <div
                                    name=""
                                    id=""
                                    class="form-control form-control-sm bg-dark ps-2 d-flex"
                                    style={{
                                      maxWidth: "70px",
                                      alignItems: "center",
                                    }}
                                    value={captainInfo?.countryCode}
                                    disabled={true}
                                  >
                                    {/* {captainInfo?.countryCode} */}
                                    {liteProfile?.country?.dialingCode}
                                    {/* {countryList?.length > 0 ? (
                                  countryList?.map((ele, index) => (
                                    <option>
                                      <img
                                        className="country_img"
                                        src={url.imageUrl + ele?.flag?.default}
                                        alt={ele?.name}
                                      />
                                      {ele?.dialingCode}
                                    </option>
                                  ))
                                ) : (
                                  <></>
                                )} */}
                                  </div>
                                </div>
                              </div>
                              <div class="col ps-0 input-group">
                                <input
                                  type="text"
                                  class="form-control form-control-sm bg-dark"
                                  placeholder={t(
                                    "team_register.enter_mobile_number"
                                  )}
                                  value={captainInfo?.phoneNo}
                                  readOnly={true}
                                />
                              </div>
                            </div>
                            <div class="row align-items-center mb-2">
                              <div class="col-5">
                                <h5 class="mb-0">
                                  {t("team_register.in_game_name")}:
                                </h5>
                              </div>
                              <div class="col ps-0">
                                <input
                                  type="text"
                                  class="form-control form-control-sm bg-dark"
                                  placeholder={t("team_register.enter_name")}
                                  value={captainInfo?.userInGameName}
                                  readOnly={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {frmInitialValues?.length > 0 &&
                          frmInitialValues?.map((ele, index) => (
                            <>
                              <div class="card addPlayerCard mb-4">
                                <div
                                  class="card-header d-flex align-items-center justify-content-between"
                                  style={{ position: "relative" }}
                                >
                                  <div class="playerDesignation">
                                    {t("team_register.player", {
                                      n: index + 2,
                                    })}{" "}
                                    {ele?.isOptional &&
                                      t("team_register.optional")}
                                  </div>
                                  <h4
                                    class="mb-0 ms-4  px-10"
                                    // style={{width:'50%', position:'absolute',right:0}}
                                  >
                                    <div>
                                      <label
                                        class="form-check-label"
                                        for={`phoneNo${index}`}
                                      >
                                        {t("team_register.via_mobile")}
                                      </label>
                                    </div>
                                  </h4>
                                </div>
                                <div class="card-body">
                                  <div class="row align-items-center mb-2">
                                    <div class="col-5">
                                      <div class="input-group w-auto">
                                        <span
                                          class="input-group-text rounded-start-5 bg-dark border-0 pe-0"
                                          id="basic-addon1"
                                        >
                                          <img
                                            className="icon-24"
                                            src={
                                              url.imageUrl +
                                              liteProfile?.country?.flag
                                                ?.default
                                            }
                                            alt=""
                                          />
                                        </span>
                                        <div
                                          name=""
                                          id=""
                                          class="form-control form-control-sm bg-dark ps-2 d-flex"
                                          style={{
                                            maxWidth: "70px",
                                            alignItems: "center",
                                          }}
                                          value={captainInfo?.countryCode}
                                          disabled={true}
                                        >
                                          
                                             {liteProfile?.country?.dialingCode}
                                          
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="col ps-0 "
                                      style={{ position: "relative" }}
                                    >
                                      {ele?.type != "phoneNo" ? (
                                        <input
                                          type="email"
                                          class="form-control form-control-sm bg-dark"
                                          placeholder={
                                            ele?.type != "phoneNo"
                                              ? t("team_register.enter_email")
                                              : t(
                                                  "team_register.enter_mobile_number"
                                                )
                                          }
                                          value={
                                            frmInitialValues[index]?.phoneNo
                                          }
                                          readOnly={ele?.isSubmit && true}
                                          onChange={(e) => {
                                            // let formdata = [...frmInitialValues];
                                            // let fromdataobj = { ...formdata[index] };
                                            // fromdataobj.phoneNo = e.target.value;
                                            // formdata[index] = fromdataobj;
                                            // setFrmInitialValues(formdata);
                                            setMobileNo(e.target.value);
                                          }}
                                        />
                                      ) : (
                                        <input
                                          type="number"
                                          class="form-control form-control-sm bg-dark"
                                          placeholder={t(
                                            "team_register.enter_mobile_number"
                                          )}
                                          readOnly={ele?.isSubmit && true}
                                          // onKeyDown={(e) => {
                                          //   if (new RegExp(/[0-9]/).test(e.key)) {
                                          //   } else e.preventDefault();
                                          // }}
                                          onKeyDown={(e) =>
                                            ["e", "E", "+", "-"].includes(
                                              e.key
                                            ) && e.preventDefault()
                                          }
                                          value={frmInitialValues[
                                            index
                                          ]?.phoneNo.slice(0, 10)}
                                          onChange={(e) => {
                                            let formdata = [
                                              ...frmInitialValues,
                                            ];
                                            let fromdataobj = {
                                              ...formdata[index],
                                            };
                                            fromdataobj.phoneNo =
                                              e.target.value;
                                            formdata[index] = fromdataobj;
                                            setFrmInitialValues(formdata);
                                          }}
                                          // style={{ paddingRight: "70px" }}
                                        />
                                      )}
                                      {ele?.isSubmit ? (
                                        <button
                                          class="btn btn-primary btn-sm rounded-5 add-button"
                                          type="button"
                                          onClick={() =>
                                            handleUsrBtnRemove(index)
                                          }
                                          // style={{position:'absolute',right:'4.3%',top:'0',height:'100%',lineHeight:'1'}}
                                        >
                                          {t("team_register.remove")}
                                        </button>
                                      ) : (
                                        <button
                                          class="btn btn-primary btn-sm rounded-5 add-button"
                                          // style={{position:'absolute',right:'4.3%',top:'0',height:'100%',lineHeight:'1'}}
                                          type="button"
                                          onClick={() => handleUsrBtn(index)}
                                        >
                                          {t("team_register.add")}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                  <div class="row align-items-center mb-2">
                                    <div class="col-5">
                                      <h5 class="mb-0">
                                        {t("team_register.in_game_name")}
                                      </h5>
                                    </div>
                                    <div class="col ps-0">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm bg-dark"
                                        placeholder={t(
                                          "team_register.enter_name"
                                        )}
                                        value={
                                          frmInitialValues[index]?.inNameGame
                                        }
                                        onChange={(e) => {
                                          let formdata = [...frmInitialValues];
                                          let fromdataobj = {
                                            ...formdata[index],
                                          };
                                          fromdataobj.inNameGame =
                                            e.target.value;
                                          formdata[index] = fromdataobj;
                                          setFrmInitialValues(formdata);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div class="row align-items-center">
                                    <div class="col-5">
                                      <h5 class="mb-0">
                                        {t("team_register.mobily_name")}
                                      </h5>
                                    </div>
                                    <div class="col ps-0">
                                      <input
                                        type="text"
                                        class="form-control form-control-sm bg-dark"
                                        placeholder={t("team_register.enter_name")}
                                        value={
                                          frmInitialValues[index]?.gamerjiName
                                        }
                                        onChange={(e) => {
                                          let formdata = [...frmInitialValues];
                                          let fromdataobj = {
                                            ...formdata[index],
                                          };
                                          fromdataobj.gamerjiName =
                                            e.target.value;
                                          formdata[index] = fromdataobj;
                                          setFrmInitialValues(formdata);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        {err && <Error error={err} />}

                        <div class="d-grid mt-3 pt-1">
                          <button
                            type="button"
                            class="btn btn-primary btn-arrow"
                            onClick={handleSubmit}
                          >
                            {t("team_register.save_team")}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TeamRegisterScrim;
