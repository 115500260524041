import React from "react";
import FeaturedVideos from "./FeaturedVideos";
import PopulatVideos from "./PopulatVideos";
import TopProfiles from "./TopProfiles";
import News from "./News";

const WorldOfEsports = ({ handleClickBlog }) => {
  return (
    <div class="row gy-5">
      <FeaturedVideos />
      <PopulatVideos />
      <TopProfiles />
      <News handleClickBlog={handleClickBlog} />
    </div>
  );
};

export default WorldOfEsports;
