import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../../Utilities/routesFb";
import moment from "moment";
import NoContestFound from "../../../assets/images/noContestFound.png";
import { getHowToCreateScrim, scrimListByUserId } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { errors } from "../../../Utilities/error";
import { toast } from "react-toastify";
import JoinViaInviteCodeModal from "../JoinViaCode/JoinViaInviteCodeModal";
import Loader from "../../Common/Loader";
import HowToCreateContest from "./HowToCreateContest";
import Pagination from "../../Common/Pagination";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
const PrivateContest = () => {
  const [scrimList, setScrimList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const gameName = localStorage.getItem("activeGame");
  const gameId = localStorage.getItem("activeGameId");
  const [showJVC, setShowJVC] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showHowTo, setShowHowTo] = useState()
  const [steps, setSteps] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const { profileData, updateProfileData } = useProfileDataContext()
  const { t } = useTranslation();
  let limit = 6
  const getScrimList = async (pageNo) => {
    setLoading(true);
    try {
      // let userData = localStorage.getItem("userData");
      // userData = JSON.parse(userData);

      let data = {
        user: profileData?.item?.user_id,
        page: pageNo,
        limit: limit,
        sortBy: "createdAt",
        sort: "desc",
        game: gameId,
      };

      const res = await scrimListByUserId(data);

      if (res?.data?.data) {
        setScrimList(res?.data?.data);
        setTotalPage(res?.data?.totalPages);
        
        setLoading(false);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // setSkipCount((prev) => (page - 1) * limit);
    getScrimList(Math.ceil(page - 1));
  };
  useEffect(() => {
    getScrimList(0);
  }, []);
  useEffect(() => {
    getScrimList(1);
  }, []);

  const  howToCreate = async()=>{
    try{
      let id = localStorage.getItem("activeGameId")
      const res = await getHowToCreateScrim({id:id})
      func("response", res?.data?.data[0]?.param[0]?.howToCreateScrim)
      setShowHowTo(true)
      setSteps(res?.data?.data[0]?.param[0]?.howToCreateScrim)
    }catch(error){
      toast.error(error?.response?.data?.errors[0]?.msg)
    }
  }
  const loadMoreBtnClick = () => {
    let totalCount = parseInt(page) + 1;
    setPage(totalCount);
    getScrimList(totalCount);
  };

  const findPercentage = (data) => {
    let totalPlayer = data?.noOfPlayerCapacity || 0;
    let totalPlayerJoined = data?.noOfJoinedPlayer || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage + "%";
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div class="row gy-5">
        <div class="col-12">
        <div class="mb-5 pb-3 d-flex flex-md-row flex-column align-items-md-center justify-content-md-between">
            <h2 class="h3 bodyBoldFont mb-4">{t('private_contest.private_contest')}</h2>
            <div class="d-flex">
              <Link
                onClick={() => setShowJVC(true)}
                class="btn btn-outline-primary btn-sm px-5 text-capitalize fs-3"
              >
                  {t('private_contest.join_a_contest')}
              </Link>
              <Link
                to={routes.createPrivateContest}
                class="btn btn-primary btn-sm text-capitalize ms-4 px-5 fs-3"
              >
                  {t('private_contest.create_a_contest')}
              </Link>
            </div>
          </div>
          {scrimList.length > 0 ? (
            <>
              <div
                class="row gy-5 gx-2"
                // style={{ flexWrap: "wrap", columnGap: "10px", rowGap: "100px" }}
              >
                {scrimList.map((item, ind) => (
                  <div  className="col-lg-4 col-md-6 col-12"  key={ind}>
                    <div class="card tournametParticipationCard tournamentSoloCard contestTournamentSoloCard mb-5">
                      <div class="card-header">
                        <div class="card-status">
                          {item?.status?.toUpperCase()}
                        </div>
                        <h4 class="card-title mb-0">{item?.title}</h4>
                        {/* <a href="#" class="rulesLink fs-5">ID: 2HWEJV</a> */}
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="card-content">
                              <div class="row justify-content-between py-2">
                                <div class="col-3">
                                  <label for="" class="d-block h6">
                                  {t('contest_details.date')}
                                  </label>
                                  <h6 class="bodyBoldFont mb-0">
                                    {moment(item?.date).format("DD/MM/YYYY")}
                                  </h6>
                                </div>
                                <div class="col-3 dividersX">
                                  <label for="" class="d-block h6">
                                  {t('contest_details.time')}
                                  </label>
                                  <h6 class="bodyBoldFont mb-0">
                                    {moment(item?.time).format("hh:mm A")}
                                  </h6>
                                </div>
                                <div class="col-3 dividersRight">
                                  <label for="" class="d-block h6">
                                    {/* {item?.titles[0]?.name} */}
                                    {item?.titles[0]?.name?.length > 9
                                      ? item?.titles[0]?.name?.substring(
                                          0,
                                          9 - 3
                                        ) + "..."
                                      : item?.titles[0]?.name}
                                  </label>
                                  <h6 class="bodyBoldFont mb-0">
                                    {/* {item?.titles[0]?.value} */}
                                    {item?.titles[0]?.value?.length > 9
                                      ? item?.titles[0]?.value?.substring(
                                          0,
                                          9 - 3
                                        ) + "..."
                                      : item?.titles[0]?.value}
                                  </h6>
                                </div>
                                <div class="col-3">
                                  <label for="" class="d-block h6">
                                    {/* {item?.titles[1]?.name} */}
                                    {item?.titles[1]?.name?.length > 9
                                      ? item?.titles[1]?.name?.substring(
                                          0,
                                          9 - 3
                                        ) + "..."
                                      : item?.titles[1]?.name}
                                  </label>
                                  <h6 class="bodyBoldFont mb-0">
                                    {/* {item?.titles[1]?.value} */}
                                    {item?.titles[1]?.value?.length > 9
                                      ? item?.titles[1]?.value?.substring(
                                          0,
                                          9 - 3
                                        ) + "..."
                                      : item?.titles[1]?.value}
                                  </h6>
                                </div>
                              </div>
                              <div class="row justify-content-between py-2 mt-1">
                                <div class="col-4">
                                  <label for="" class="d-block h6">
                                  {t('contest_details.players')}
                                  </label>
                                  <div class="d-flex align-items-center poolPrizeBox">
                                    <h6 class="bodyBoldFont mb-0">
                                      {item?.noOfPlayerCapacity || 0}
                                    </h6>
                                  </div>
                                </div>
                                <div class="col-4 dividersX d-flex">
                                  <div class="">
                                    <label for="" class="d-block h6">
                                    {t('tournaments.winners')}
                                    </label>
                                    <div class="d-flex align-items-center poolPrizeBox">
                                      <h6 class="bodyBoldFont mb-0">
                                        {item?.noOfWinner || 0}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-4 d-flex">
                                  <div class="">
                                    <label for="" class="d-block h6">
                                    {t('contest_details.id')}
                                    </label>
                                    <div
                                      class="d-flex align-items-center useCoinBox"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          item?.shortCode
                                        );
                                        toast.info(t('info_Copied'), {
                                          // position: "top-left",
                                          autoClose: 2000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <h6 class="bodyBoldFont mb-0">
                                        {item?.shortCode}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="col-12">
                                  <div
                                    class="progress"
                                    role="progressbar"
                                    aria-label="Basic example"
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    <div
                                      class="progress-bar bg-info"
                                      style={{ width: findPercentage(item) }}
                                    ></div>
                                  </div>
                                  <div class="d-flex justify-content-between mt-2">
                                    <h6>
                                    {t('contest_details.players_remaining',{players:item?.noOfPlayerCapacity -
                                        item?.noOfJoinedPlayer || 0})}       
                                    </h6>
                                    <h6>
                                    {t('contest_details.players_joined',{players:item?.noOfJoinedPlayer || 0})}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer text-white">
                        <div class="bg-secondary-subtle pt-2 pb-1 box text-center">
                          <p class="mb-0">{t('private_contest.hosted_by')} - {item?.hostedByName}</p>
                          <hr class="my-1 border-white opacity-100" />
                          <Link
                            to={
                              "/home/" +
                              routes.privateContest +
                              "/manage-contest/" +
                              item?._id
                            }
                            class="text-white text-decoration-none text-uppercase mt-1 d-inline-block"
                          >
                             {t('private_contest.manage')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* {page < totalPage && (

                <div class="d-flex justify-content-center mt-5">
                  <button
                    class="btn btn-primary col-2 text-center mt-5"
                    onClick={() => loadMoreBtnClick()}
                  >
                    Load More
                  </button>
                </div>
              )} */}
               {totalPage > 1 && (
             
             <Pagination currentPage = {currentPage} totalPages = {totalPage} onPageChange={handlePageChange} />
           )}
            </>
          ) : (
            <>
              <div class="text-center">
                <img className="img-fluid" src={NoContestFound} alt="" />
                <div className="cardLinks mt-5 d-flex flex-column">
                  <p>{t('private_contest.it_seems_like_you_havent_created_any_contest_yet')}</p>
                  <Link
                    className="bodyBoldFont text-capitalize"
                    style={{marginTop:'5px', fontSize:'1.25rem'}}
                    onClick={() => {
                      howToCreate()
                    }}
                  >
                  {t('private_contest.how_to_create_a_contest')}
                  </Link>
                </div>
              </div>
              
            </>
          )}
        </div>
      </div>
      {showJVC && (
        <JoinViaInviteCodeModal showJVC={showJVC} setShowJVC={setShowJVC} />
      )}
      {
        showHowTo && (
          <HowToCreateContest showHowTo={showHowTo} setShowHowTo={setShowHowTo} steps={steps} />
        )
      }
     
    </>
  );
};

export default PrivateContest;
