import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import { func } from "../../../Utilities/logFunc";
import { getPrivacyPolicy } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";

const PrivacyPolicy = ({ type }) => {
  let [termsOfUseData, setTermsOfUseData] = useState([]);

  const fetchTermsOfUseData = async () => {
    try {
      if (type === "privacy-policy") {
        const res = await getPrivacyPolicy();
        if (res?.data?.item) {
          setTermsOfUseData(res?.data?.item);
        }
        func(res);
      }
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    fetchTermsOfUseData();
  }, [type]);

  return (
    <>
      {termsOfUseData?.description && (
        <div
          dangerouslySetInnerHTML={{ __html: termsOfUseData?.description }}
        />
      )}
    </>
  );
};

export default PrivacyPolicy;
