import React, { useEffect, useState } from "react";
// import Background from "./images/header_bg.png"
import slice4 from "./images/glo_hero.png"
import CardSection from "./CardSection";
import LandingPage from "./LandingPage";
import { Link } from "react-router-dom";
import Loader from "../Common/Loader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { createNewsLetter } from "../../Utilities/controller";
import { getSubscriptionTypeList } from "../../Utilities/controller";
import { WidthFull } from "@mui/icons-material";
const LandingMain = () => {
  const [active, setActive] = useState(5);
  const [codeVal, setCodeVal] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const handlerSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!codeVal || codeVal.length <= 0) {
      setError("Please enter your email id");
    } else {
      if (ValidateEmail(codeVal)) {
        setLoading(true);
        try {
          const res = await createNewsLetter({ email: codeVal?.toLowerCase() });
          if (res?.data?.success) {
            setCodeVal("");
            toast.success("Newsletter submitted successfully");
          } else {
            setError("Please enter valid email id");
          }
          setLoading(false);
        } catch (error) {
          setError(error?.response?.data?.errors?.[0]?.msg);
          setLoading(false);
        }
      } else {
        setError("Please enter valid email id");
      }
    }
  };

  function ValidateEmail(input) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    fetchList()
  }, [])
  const fetchList = async()=>{
    // if(liteProfile?.country?._id) {
      let payload = {
          country: '65d6e6a69ec04282924dbcb7',
          company:process.env.REACT_APP_COMPANY_ID
      }
      try{
          // alert('jaimin');
          const res = await getSubscriptionTypeList(payload)
          console.log("subscription list 13", res?.data?.data?.[0]?.param?.data)
          setList(res?.data?.data?.[0]?.param?.data)
      }
      catch(error){
        toast.error(error?.response?.data?.errors?.[0]?.msg);
      }
    // }
  }
  return (
    <LandingPage active={active} setActive={setActive}>
      {isLoading && <Loader />}
      <ToastContainer />
      <div className="landingBody"></div>
    </LandingPage>
  );
};

export default LandingMain;
