import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { url } from "../../../Utilities/url";
import { accountCheckEmail } from "../../../Utilities/controller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import { logout } from "../../../Utilities/logout";
import { errors } from "../../../Utilities/error";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";

const EmailVerificationModal = ({
  emailVerificationModal,
  setEmailVerificationModal,
  sendRequest,
}) => {
  const [show, setShow] = useState(emailVerificationModal);
  const { fetchAccountProfile } = useProfileDataContext()
  const { t } = useTranslation();

  const handleClose = () => {
    setEmailVerificationModal(false);
    setShow(false);
    sendRequest(false);
  };

  const handleSubmit = async (values) => {
    try {
      if (frmInitialValues?.type === "otpRequest") {
        if (
          values?.email != "" &&
          values?.email != "null" &&
          values?.email != "undefined" &&
          values?.email != null &&
          values?.email != undefined
        ) {
          let data = {
            type: "otpRequest",
            email: values?.email,
          };

          const res = await accountCheckEmail(data);

          if (res?.data?.success) {
            let fromdataobj = { ...frmInitialValues };
            fromdataobj.isSubmitEmail = true;
            fromdataobj.type = "validateOTP";
            setFrmInitialValues(fromdataobj);
          } else {
            toast.error(res?.data?.errors?.[0]?.msg);
          }
        } else {
          
          toast.error(t('error_Please_enter_a_valid_email_address'));

        }
      } else {
        if (
          values?.otp != "" &&
          values?.otp != "null" &&
          values?.otp != "undefined" &&
          values?.otp != null &&
          values?.otp != undefined
        ) {
          let data = {
            type: "validateOTP",
            email: values?.email,
            otp: values?.otp,
          };

          const res = await accountCheckEmail(data);

          if (res?.data?.success) {
            sendRequest(true);
            setEmailVerificationModal(false);
            fetchAccountProfile()
            setShow(false);
          } else {
            toast.error(res?.data?.errors?.[0]?.msg);
          }
        } else {
          toast.error(t('error_enter_code'));
        }
      }
    } catch (error) {
      // if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      // logout()
      setEmailVerificationModal(false);
      sendRequest(false);
      setShow(false);
      toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const [frmInitialValues, setFrmInitialValues] = useState({
    email: "",
    isSubmitEmail: false,
    otp: "",
    type: "otpRequest",
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <Formik
          enableReinitialize={true}
          initialValues={frmInitialValues}
          //   validationSchema={applyPromoCodeSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            // handleSubmit,
            errors,
            values,
            // setFieldValue,
            // validateField,
            // validateForm,
          }) => (
            <Form>
              <div
                class="col-12 justify-content-center align-items-center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 class="bodyBoldFont mb-5 text-center">
                  Email Verification
                </h3>

                {frmInitialValues?.isSubmitEmail && (
                  <span className="mb-5">
                    We have sent an OTP on your email{" "}
                    <span className="bodyBoldFont">
                      {frmInitialValues?.email}
                    </span>
                  </span>
                )}

                <div className="col-6 mb-5">
                  {!frmInitialValues?.isSubmitEmail ? (
                    <>
                      <label for="email" className="form-label">
                      {t('reward_store.email')}
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder={t('reward_store.enter_email')}
                        name="email"
                        onChange={(e) => {
                          let fromdataobj = { ...frmInitialValues };
                          fromdataobj.email = e.target.value;
                          setFrmInitialValues(fromdataobj);
                        }}
                      />
                      {errors.email ? <div>{errors.email}</div> : null}
                    </>
                  ) : (
                    <>
                      <label for="otp" className="form-label">
                      {t('reward_store.otp')}
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="otp"
                        placeholder= {t('reward_store.enter_otp')}
                        name="otp"
                        onChange={(e) => {
                          let fromdataobj = { ...frmInitialValues };
                          fromdataobj.otp = e.target.value;
                          setFrmInitialValues(fromdataobj);
                        }}
                        maxLength={6}
                        onKeyPress={(e) => {
                          if (new RegExp(/[0-9]/).test(e.key)) {
                          } else e.preventDefault();
                        }}
                      />
                      {errors.otp ? <div>{errors.otp}</div> : null}
                    </>
                  )}
                </div>

                <div className="col-6">
                  <button
                    className="btn btn-primary btn-arrow"
                    style={{ width: "100%" }}
                  >
                    {t('crm.submit')}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EmailVerificationModal;
