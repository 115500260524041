import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import FeaturedTournament from "./FeaturedTournament";
import AllGames from "./AllGames";

import { func, func1 } from "../../Utilities/logFunc";
import { createTangerineSubscriptionNew, getAccountDailyReward, getAccountProfile, getDailyReward, isUserPremium } from "../../Utilities/controller";
import DailyLoginModal from "../Profile/DailyLoginModal";
import DailyLoginRewardModal from "./DailyLoginReward/DailyLoginRewardModal";
import JoinViaInviteCodeModal from "./JoinViaCode/JoinViaInviteCodeModal";
import TangerinePaymentStatus from "../Subscription/TangerinePaymentStatus";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useProfileDataContext } from "./ProfileContext";
import Loader from "../Common/Loader";

const Main = ({ handleClickGame }) => {
  console.log("homeeeeeee Main" )
  const [accountDailyReward, setAccountDailyReward] = useState(null);
  const [showDLR, setShowDLR] = useState(false);
const [dailyRewardList, setDailyRewardList] = useState([]);
const [showJVC, setShowJVC] = useState(true)
  const [searchParams] = useSearchParams()
  const { profileData, updateProfileData } = useProfileDataContext()
  const [isTangerineSubscription, setIsTangerineSubscription ] = useState(false)
  const [tangerineParams, setTangerineParams ] = useState({})
  const [loader, setLoader] = useState(false)
    // console.log(code.get('code'), "***********")
  const fetchDailyRewardList = async (pageNo) => {
    try {
      //   console.log("user_data", user_data);

      let data = {
        skip: 0,
        limit: 100,
        day: accountDailyReward?.currentDay,
        sortBy: "day",
        sort: "asc",
      };

      const res = await getDailyReward(data);

      if (res?.data?.list) {
        setDailyRewardList(res?.data?.list);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };
 
  
  const fetchAccountDailyRewardList = async () => {
    try {
      const res = await getAccountDailyReward();

      if (res?.data?.success) {
        setAccountDailyReward(res?.data?.item);
      }
      
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };
  // const fetchGetSettings = ()=>{
  //   try{
  //     const res = await getSettingsList()
  //   }
  // }

  const fetchNewCreateTan = async(params)=>{
    try{
      let body = {
        "packName": params.packName,
        "planName": params.planName,
        "userId": params.userId,
        "providerName": params.providerName,
        "providerType": params.providerType,
        "userIdType": params.userIdType,
        "status": params.status,
        "signature": params.signature,
        "extendedParameters": {
          "correlatorId": params.correlatorId
        }
      };
      const res = await createTangerineSubscriptionNew(body);
      // console.log(res)
    if (res.data.success) {
      localStorage.setItem('token', res?.data.token)
      if(res?.data?.isDetailsFilled)
        window.location.href = '/home'
      else
        window.location.href = '/avatar'
      setLoader(false);
    } else {
    }
    }
    catch(err){
      setLoader(false);
      console.log(err)
    }
    
      }

  useEffect(() => {
    console.log("homeeeeeee")
    setLoader(true)
   
    if(localStorage.getItem('token')){
      // fetchAccountDailyRewardList()
      // fetchDailyRewardList()
      if(searchParams.get("correlatorId")) {
        const params = {};
  
        for (let entry of searchParams.entries()) {
          // console.log(entry[1])
          params[entry[0]] = entry[1]
          // params.push(entry);
      }
        setTangerineParams(params)
        setIsTangerineSubscription(true);
        
      }
      setLoader(false)
    }
    else{
      if(searchParams.get("correlatorId")) {
        const params = {};
  
        for (let entry of searchParams.entries()) {
          // console.log(entry[1])
          params[entry[0]] = entry[1]
          // params.push(entry);
      }
       fetchNewCreateTan(params)
      }
    }

    // fetch()
  }, []);
  
  return (
    <>
    {
      loader ? <Loader />
       : 
    
    <div class="content">
      <div className="content-inner">
      <div class="row gy-4">
        {isTangerineSubscription && <TangerinePaymentStatus show={isTangerineSubscription} tangerineParams={tangerineParams} closePaymentPopUp={() => setIsTangerineSubscription(false)}/> }
        <Hero  paramTan={tangerineParams}/>
        <FeaturedTournament />
        <AllGames handleClickGame={handleClickGame}  paramTan={tangerineParams} />
        {
          dailyRewardList.length >= accountDailyReward?.currentDay && accountDailyReward?.isDailyRewardPopup  ? <DailyLoginRewardModal showDLR={true} setShowDLR={setShowDLR} />:""
        }
        {
          searchParams.get('code') && 
          <JoinViaInviteCodeModal showJVC={showJVC} setShowJVC={setShowJVC} code ={searchParams.get('code')} />
        }
        
        </div>
      </div>
    </div>
      }
      </>
  );
  
};

export default Main;
