// firebaseAnalytics.js
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent ,setUserId} from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAytnhAzygmqRa5FsxW0ikgBJbEYD6mGS8",
  authDomain: "ooredoo---powerplay.firebaseapp.com",
  projectId: "ooredoo---powerplay",
  storageBucket: "ooredoo---powerplay.appspot.com",
  messagingSenderId: "1040581439845",
  appId: "1:1040581439845:web:f781f270098171a2cef18c",
  measurementId: "G-TKPWGBS74J"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

//setUserId(analytics, "GU-222222");

//log event
const eventTracking=(event,parameters)=>{
  //OP_UserID, OP_TimeStamp
  var currentDate = new Date().toTimeString();
  var profileData = JSON.parse(localStorage.getItem("profile"));
  console.log(profileData);
  var customParam ={...parameters,OP_TimeStamp:currentDate };
  if(profileData!=null && profileData?.item?.user_id){
    customParam.OP_UserID = profileData?.item?.user_id;
  }else if(profileData!=null && profileData?.item?._id){
    customParam.OP_UserID = profileData?.item?._id;
  }
  console.log("Event Triggered",event,customParam)
  logEvent(analytics, event, customParam);
}

export { analytics, logEvent,eventTracking };


 


