import React, { useEffect, useReducer, useState } from "react";
import BaseAuth from "./BaseAuth";
import {
  getCountryList,
  createSubscription
} from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import Loader from "../Common/Loader";
import { url } from "../../Utilities/url";
// import { errors } from "../../Utilities/error";
import { useLocation } from "react-router-dom";
import { INITIAL_STATE, loginReducer } from "./loginReducer";
// import { INITIAL_STATE_REG, registerReducer } from "./SignupReducer";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import LoginLandingPage from "../LandingPage/LoginLandingPage";
import { useTranslation } from "react-i18next";
import { encryptKey } from "../../Utilities/encryptDecrypt";

const Plans = (props) => {
  func("login", "login page");
  const [countryList, setCountryList] = useState([]);
  const [loginInput, dispatchInput] = useReducer(loginReducer, INITIAL_STATE);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [index, setIndex] = useState(0);
  // const navigate = useNavigate();
  
  const {state} = useLocation()
  const { t } = useTranslation();

  // const translatedText = t("login.i_agree_to_mobily", {
  //   interpolation: { escapeValue: false },
  // });
  const fetchCountry = async () => {
    setLoading(true);
    try {
      const res = await getCountryList();
      if (res) setCountryList((prev) => res?.list);
      // setCountryOption(prev=> res?.list[2].dialingCode)
      let index = null;
      res?.list?.filter((item, ind) => {
        if (item?.dialingCode === "+234") {
          index = ind;
          return index;
        }
      });
      func("index", index);
      setIndex(index);
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: res?.list?.[index]?.dialingCode,
        },
      });
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: res?.list?.[index]?._id },
      });
      setLoading(false);
    } catch (error) {
      func("error", error);
      setLoading(false);
    }
    
  };
  useEffect(() => {
    fetchCountry();
  }, []);
  const onChangeHandler = (e) => {
    setError("")
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const loginHandler = (e) => {
    e.preventDefault();
    setError("")
    // setPinError("")
    if (!loginInput?.mobileNumber || loginInput?.mobileNumber?.length === 0) {
      setError(t('error_enter_phone'));
    } 
    else if (loginInput?.countryOption === "+91") {
      if (loginInput?.mobileNumber.length !== 10) {
        setError(t('error_enter_valid_phone'));
      } else {
        callPaymentAPI()
        // callToOTPRequestAPI();
      }
    } else {
      if (loginInput?.mobileNumber.length < 8 || loginInput?.mobileNumber.length > 14 ) {
        setError(t('error_enter_valid_phone'));
      } else {
          callPaymentAPI()
        // callToOTPRequestAPI();
      }
    }
  }
  const callPaymentAPI = async() => {
    try {
      setLoading(true);
      let phone = loginInput?.countryOption.replace("+","") + loginInput.mobileNumber
      const encPhone = await encryptKey(phone);
      let payload = {
        planId: state.xsollId,
        msisdn: encPhone
      }
      const res = await createSubscription(payload);
      console.log(res.data);
      if(res.data.success) {
        setLoading(false);
        toast.success(res.data.msg)
        setTimeout(() => {
          // localStorage.setItem('country', res?.data?.sendData?.item?.country)
          // localStorage.setItem('token', res?.data?.sendData?.token)
          // localStorage.setItem("isDetailsFilled", res?.data?.sendData?.item?.isDetailsFilled);
          window.location.href = '/';
        }, 3000);
        
      } else {
        setLoading(false);
        toast.error(res.data.msg)
      }

    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg)
    }
  }
  // const callToOTPRequestAPI = async () => {
  //   const encPin = await encryptKey(loginInput?.pin);
  //   var payload = {
  //     type: "otpRequest",
  //     username: loginInput?.mobileNumber,
  //     phoneCode: loginInput?.countryOption,
  //     country: loginInput?.code,
  //     pin: encPin,
  //   };
  //   // const encPin = encryptKey(e.target.value.slice(0,6))

  //   // localStorage.setItem("loginInfo", JSON.stringify(payload));

  //   setLoading(true);
  //   try {
  //     const res = await userRequestOTP(payload);
  //     // func("login res", res?.response?.data?.errors?.[0]?.msg);
  //       console.log("data", res)
  //       localStorage.setItem("country", res?.item?.address?.country);
  //       localStorage.setItem("token", res?.token);
  //       localStorage.setItem(
  //         "d11_username",
  //         res?.item?.gamerjiName
  //       );
  //       if (res?.item?.isDetailsFilled) window.location.href = "/" + routes.home;
  //       else window.location.href = "/avatar";
  //     // if (res?.success) {
  //     //   navigate("/" + routes.otp, { state: loginInput });
  //     // } else {
  //     //   setError(res?.response?.data?.errors?.[0]?.msg);
  //     // }
  //   } catch (error) {
  //     // console.log("userRequestOTP error", error?.response.data);
  //     if (error?.response?.data?.message) {
  //       setError(error?.response?.data?.message);
  //     } else if (error?.response?.data?.errors?.[0].msg) {
  //       setError(error?.response?.data?.errors[0].msg);
  //     }
  //     // console.log(error?.response?.data?.errors[0].msg)
  //     // setPinError(error?.response?.data?.errors[0].msg)
  //     // setError(error?.response?.data?.errors[0].msg);
  //   }
  //   setLoading(false);
  // };

  const handlerSelect = (e) => {
    setCountryCode(e);
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };

  const customStyles = {

    // menu: (defaultStyles, state) => ({
    //   ...defaultStyles,
    //   // backgroundColor: "red",
    //   minHeight:'15rem',
    //   backgroundColor: "var(--secondary-color-emphasis)",
    // }),

    option: (defaultStyles, state) => ({
      ...defaultStyles,
      // color: state.isSelected ? "#fff" : "#fff",
      // backgroundColor: state.isSelected
      //   ? "var(--secondary-color-emphasis)!important"
      //   : "purple",
      //   height:'2.5rem',
      //   "&:hover" : {
      //     color:'var(--accent-color)'
      //   },
      //   padding:"0.65rem 1.25rem",
      //   borderRadius:'20px'
      display:'none'
      
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "var(--secondary-color-emphasis)",
      padding: "0.5rem",
      border: "none",
      boxShadow: "none",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
      caretColor:'transparent',
      minHeight:'3.75rem'
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      width: "4.7rem",
      marginRight: "0.75rem",
      padding: "0.35rem",
     
    }),
    indicatorsSeparator: (defaultStyles) => ({
      ...defaultStyles,
      // color: "#fff",
      // display: "none",
      display:'none'
    }),
    indicatorContainer: (defaultStyles) => ({
      ...defaultStyles,
      // padding: "0",
      // backgroundColor: "red",
      display:'none'
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      // color: "#fff",
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "space-between",
      // height: "1.85rem",
      display:'none'
      
    }),
    dropdownIndicator:(defaultStyles) => ({
    
      // color:'var(--color-white)',
      // width:'1.5rem',
      // height:'1.5rem',
      // padding:'0',
      // display:'flex',
      // justifyContent:'center',
      // alignItems:'center',
      display:'none'
    })
  };
  return (
    <>
      <ToastContainer />
      {isLoading &&<Loader />}
        <LoginLandingPage>
          <BaseAuth>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-sm-9">
                
                <div className="tab-content auth-content marginTop">
                  
                  <div>
                    <form className="row">
                      <div className="col-12">
                        <h2 class="daily-text">{t("login.current_active_plan", {Daily: state.packName, 100: state.amount, Day:state.packName })}</h2>
                      </div>
                      <div className="col-4">
                        <label
                          for="inputCoutryCode"
                          className="form-label text-nowrap"
                        >
                          {t("login.country_code")}
                        </label>
                        {
                          !isLoading &&
                            <Select
                              styles={customStyles}
                              name="countryOption"
                              // value={countryCode}
                              defaultValue={countryList?.[index]}
                            // options={countryList}
                              onChange={(e) => handlerSelect(e)}
                              getOptionLabel={(ele) => (
                                <div
                                  className="d11-menu"
                                  style={{ display: "flex", alignItems: "center" ,    paddingLeft: "0.9rem"}}
                                >
                                  <span class="icon">
                                    <img
                                      class="me-1"
                                      // style={{
                                      //   borderRadius: "50%",
                                      //   width: "24px",
                                      //   height: "24px",
                                      //   marginRight: "5px",
                                      // }}
                                      src={url.imageUrl + ele?.flag?.default}
                                      alt=""
                                    />
                                  </span>{" "}
                                  {ele?.dialingCode}
                                </div>
                              )}
                            />
                        }
                       {/* <CountryDropDown options={countryList} /> */}
                      </div>
                      
                      <div className="col-8">
                        <label for="inputMobileNumber" className="form-label">
                        {t("login.mobile_number")}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="inputMobileNumber"
                          placeholder={t("login.enter_mobile_number")}
                          name="mobileNumber"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) => onChangeHandler(e)}
                          maxLength={14}
                          value={loginInput?.mobileNumber.slice(0, 14)}
                        />
                      </div>
                      <div className="col-12 d-grid">
                        <button
                          className="btn btn-primary btn-arrow"
                          onClick={(e) => loginHandler(e)}
                        >
                          {/* {t("login.login")} */}
                          Submit
                        </button>
                        {error && (
                          <p className="error" style={{ textAlign: "center" }}>
                            {error}
                          </p>
                        )}
                      </div>
                    </form>
                    </div>
                  </div>
                </div>
              </div>
          </BaseAuth>
        </LoginLandingPage>
    </>
  );
};
export default Plans;
