import React, { useState } from 'react'
import { useTheme } from '../../ThemeContext'


import LandingNavbar from './LandingNavbar'
import './landingPage.css'
import './queries.css';
import CardSection from './CardSection';
import LandingMain from './LandingMain';
import Contact from './Contact';
import { Login } from '@mui/icons-material';
import Footer from './Footer';
const LandingPage = ({children,active}) => {
    const globalvalues = useTheme()
  
  return (
    <div className='landing-authModule' style={{position:'relative'}}>
       {/* <div class="main-bg"> */}
     <LandingNavbar active={active}  />
{children}
     {/* </div> */}
    </div>
  )
}

export default LandingPage