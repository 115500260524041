import React, { useEffect, useState } from "react";
import Diamond from "../../../assets/images/diamond-icon.png";
import Fea from "../../../assets/images/featured-1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import Reward from "../../../assets/images/rewards.png";

import {
  rewardCategoryList,
  getAccountProfile,
} from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { url } from "../../../Utilities/url";
import moment from "moment";
import RedeemRewardModal from "./RedeemRewardModal";
import EmailVerificationModal from "../EmailVerification/EmailVerificationModal";

import Loader from "../../Common/Loader";
import MyTransactionModal from "../CoinStore/MyTransactionModal";
import { useTranslation } from "react-i18next";
import { useProfileDataContext } from "../ProfileContext";

const MyReward = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation();
  const [rewardList, setRewardList] = useState([]);
  const [redeemRewardModal, setRedeemRewardModal] = useState(false);
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  const [activeRedeemReward, setActiveRedeemReward] = useState(null);
  const [userDataInfo, setUserData] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [showMTM, setShowMTM] = useState(false);
  const { liteProfile } = useProfileDataContext();
  const getRewardList = async () => {
    setLoading(true);
    try {
      let data = {
        skip: 0,
        limit: 100,
        sort: "asc",
        sortBy: "order",
      };

      const res = await rewardCategoryList(data);

      if (res?.data?.list) {
        setRewardList(res?.data?.list);
        setLoading(false);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  const getAccountProfileDetails = async () => {
    setLoading(true);
    try {
      const res = await getAccountProfile();
      if (res?.data) {
        setUserData(res?.data);
        setLoading(false);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  useEffect(() => {
    // let userData = JSON.parse(localStorage.getItem("profile"))
    // console.log(userData, "userData");
    getRewardList();
  }, []);

  const handleReward = (item) => {
    console.log(liteProfile?.item?.isEmailVerified);
    setActiveRedeemReward(item);
    {
      liteProfile?.item?.isEmailVerified
        ? setRedeemRewardModal(true)
        : setEmailVerificationModal(true);
    }
  };

  const sendRequest = (res) => {
    if (res) {
      setRedeemRewardModal(true);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {rewardList.map((ele, ind) => (
        <div class="row" key={ind}>
          <div class="mb-3 d-flex flex-row align-items-center justify-content-between">
            <h2 className="mb-0 fw-bold fs-2 ">{ele?.name}</h2>
            {ind === 0 && (
              <button
                className="d11-recenttran"
                onClick={() => setShowMTM(true)}
              >
                <img src={Reward} className="icon-30" />
                <span>{t("reward_store.my_recent_transactions")}</span>
              </button>
            )}
          </div>

          <Swiper
            className="swiper featuredTournamentSlider commonSlider"
            spaceBetween={10}
            slidesPerView={2}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            navigation
            pagination={{ clickable: true, el: ".swiper-pagination" }}
            modules={[Navigation, Pagination]}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
            style={{ cursor: "pointer" }}
          >
            <div class="col-12">
              {ele?.products?.map((elePro, ind) => (
                <SwiperSlide className="bg-transparent">
                  <div className="d11-reward">
                    <div className="d-flex flex-column pt-5">
                      <div className="d11-reward-img text-center mb-4">
                        <img src={url?.imageUrl + elePro?.img?.default} />
                      </div>
                      <div className="d11-reward-coin">
                        <div className="row">
                          <h5 className="fw-bold text-ellipsis_contest">
                            {
                              // elePro?.name.length > 25 ? (
                              //   <marquee>{elePro?.name}</marquee>
                              // ) :
                              elePro?.name
                            }
                          </h5>
                        </div>
                        <div className="second">
                          <div style={{width:'3.5rem', display:'flex', justifyContent:'space-around'}}>
                            <span>{elePro?.country?.currency?.symbol}{" "}</span>{" "}
                            <span>{elePro?.rewardVoucherAmount}</span>
                          </div>
                          <p>
                            <span className="icon me-2">
                              <img
                                className="icon-24 "
                                src={
                                  url?.imageUrl + elePro?.currency?.img?.default
                                }
                              />
                            </span>
                            {elePro?.coinAmount}
                          </p>
                        </div>
                      </div>
                      <div
                        className="d11-reward-btn bg-secondary-subtle fl-center mt-auto py-3"
                        onClick={() => handleReward(elePro)}
                      >
                        {t("reward_store.redeem")}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
            <div class="swiper-pagination"></div>
          </Swiper>
        </div>
      ))}
      {redeemRewardModal && (
        <RedeemRewardModal
          redeemRewardModal={redeemRewardModal}
          setRedeemRewardModal={setRedeemRewardModal}
          activeRedeemReward={activeRedeemReward}
          setActiveTab={setActiveTab}
        />
      )}
      {emailVerificationModal && (
        <EmailVerificationModal
          emailVerificationModal={emailVerificationModal}
          setEmailVerificationModal={setEmailVerificationModal}
          sendRequest={sendRequest}
        />
      )}
      {showMTM && (
        <MyTransactionModal showMTM={showMTM} setShowMTM={setShowMTM} />
      )}
    </>
  );
};

export default MyReward;
