import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";

const Logout = ({ logoutModal, setLogoutModal, logout }) => {
  const [show, setShow] = useState(logoutModal);
  const {t} = useTranslation()
  const handleClose = () => {
    setLogoutModal(false);
    setShow(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="row justify-content-center">
          <div class="col-12">
            <h3 class="bodyBoldFont mb-5 text-center">{t('right_hand_drop_down.logout')}</h3>
            <div className="col-lg-6 d11-logout-text mx-auto">
              <h6>{t('right_hand_drop_down.are_you_sure_want_to_logout')}</h6>
              {/* <h6>You will be able to access the website as a Guest</h6> */}
            </div>
            <div className="col-lg-6 fl-center mx-auto">
              <button
                className="btn d11-cancel-btn w-50 me-3"
                onClick={() => setLogoutModal(false)}
              >
                {t('right_hand_drop_down.cancel')}
              </button>
              <button className="btn btn-primary w-50" onClick={() => {logout();eventTracking(events.logout,{});}}>
              {t('right_hand_drop_down.logout_cta')}
              </button>
              {/* {
                                error && <p className='error' style={{ textAlign: 'center' }}>{error}</p>
                            } */}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Logout;
