import React, { useEffect, useReducer, useState } from "react";
import { Modal } from "react-bootstrap";
import { url } from "../../Utilities/url";
import DatePicker from "../Common/ProfileDatePicker";

import { func, func1 } from "../../Utilities/logFunc";
import {
  getAccountProfile,
  getAllFreeAvatar,
  getStateList,
  requestUpdateProfile,
} from "../../Utilities/controller";
import { async } from "q";
import { INITIAL_STATE, profileEditReducer } from "./profileEditReducer";
import Loader from "../Common/Loader";
import moment from "moment";
import { errors } from "../../Utilities/error";
import { routes } from "../../Utilities/routesFb";
import { Link } from "react-router-dom";
import Lock from "../../assets/images/lockIcon.png";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import Champion from "../../assets/images/dGames-champion.png";
import Master from "../../assets/images/dGames-master.png";
import { useTranslation,Trans } from "react-i18next";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";

const EditProfile = ({ showEditProfile, setEditProfile, userData }) => {
  const [show, setShow] = useState(showEditProfile);
  const [avatarError, setAvatarError] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [avatarList, setAvatarList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [avatarId, setAvatarId] = useState("");
  const [dob, setDob] = useState();
  const [state, dispatch] = useReducer(profileEditReducer, INITIAL_STATE);
  const [stateValue, setStateValue] = useState("");
  const [stateId, setStateId] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const premiumUser = JSON.parse(localStorage.getItem("isUserSubscribed"));
  const [error, setError] = useState("");
  const {t} = useTranslation()

  const handleClose = () => {
    setEditProfile(false);
    setShow(false);
  };
  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      // const res = await getAccountProfile();
      fetchAvatar();
      func("profile res", userData);
      // localStorage.setItem("profile", JSON.stringify(userData));
      setProfileData(userData);
      dispatch({
        type: "CHANE_NAME",
        payload: {
          name: "phoneCode",
          value: userData?.item?.phoneCode,
        },
      });
      dispatch({
        type: "CHANE_NAME",
        payload: {
          name: "phone",
          value: userData?.item?.phone,
        },
      });
      console.log(userData?.item?.isEmailVerified)
      if (userData?.item?.email) {
        setEmailValue(userData?.item?.email);
        dispatch({
          type: "CHANE_NAME",
          payload: {
            name: "email",
            value: userData?.item?.email,
          },
        });
      }
      if (userData?.item?.gamerjiName) {
        dispatch({
          type: "CHANE_NAME",
          payload: {
            name: "gamerjiName",
            value: userData?.item?.gamerjiName,
          },
        });
      }
      if (userData?.item?.avatar) {
        dispatch({
          type: "CHANE_NAME",
          payload: {
            name: "avatar",
            value: userData?.item?.avatar?._id,
          },
        });
      }
      setAvatarId(userData?.item?.avatar?._id);

      if (userData?.item?.dateOfBirth) {

        setDob(userData?.item?.dateOfBirth);
        dispatch({
          type: "CHANE_NAME",
          payload: {
            name: "dateOfBirth",
            value: userData?.item?.dateOfBirth,
          },
        });
      }

      let state = userData?.state?.name;
      if (!userData?.item?.address?.state) fetchStateList();
      else {
        setStateValue(state);
        func("adresssss", userData?.item?.address?.state);
        func("statevslue", userData?.item?.address?.state);
        dispatch({
          type: "CHANE_STATE",
          payload: userData?.item?.address,
        });
      }

      func("stae", state);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      func(error);
    }
  };
  const fetchAvatar = async () => {
    try {
      const res = await getAllFreeAvatar({ skip: 0, limit: 100 });
      func("avartar", res);
      setAvatarList(res?.data?.list);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
      )
        logout();
      toast.error(error?.response?.data?.errors?.[0]?.msg);
      setIsLoading(false);
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };
  const fetchStateList = async () => {
    try {
      const res = await getStateList();
      func("res state", res?.data?.list);
      func("adress", profileData?.address); //gamerjiName

      setStateList(res?.data?.list);
    } catch (error) {
      func(error);
    }
  };
  function getHTMLdateFormat(val) {
    var today = new Date(val);

    return (
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2)
    );
  }
  const onClickHandler = (item) => {
    func(
      "click handl;er",
      premiumUser && localStorage.getItem("planName") == "GrandMaster"
    );
    if (premiumUser && localStorage.getItem("planName") == "GrandMaster")
      setAvatarId(item?._id);
    else if (item?.isBuy) {
      setAvatarId(item?._id);
    } else setAvatarId("");
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  const handlerChange = (e) => {
    dispatch({
      type: "CHANE_NAME",
      payload: {
        name: e.target.name,
        value: e.target.value,
      },
    });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    let payload = {};
    // if (!profileData?.isEmailVerified) {
    //   let result = validateEmail(emailValue);
    //   func("email result", result);
    //   if (result) payload["email"] = emailValue;
    //   else {
    //     setError(t('error_Please_enter_a_valid_email_address'));
    //     return;
    //   }
    // }
    func("stateid 148", stateId);
    if (stateId) {
      payload["address"] = { ...state?.address, state: stateId };
    }
    // if (!state?.dateOfBirth) {
    //   payload["dateOfBirth"] = dob;
    // }
    payload["gamerjiName"] = state?.gamerjiName;
    // payload["avatar"] = avatarId;
    // let address = { ...profileData.address };
    // address.state = stateId;
    // func("address",address)
    func("dob", getHTMLdateFormat(dob));
    // dispatch({type:'CHANE_NAME',payload:{
    //     name:'avatar',
    //     value:avatarId
    // }})
    // dispatch({
    //     type: 'CHANE_NAME', payload: {
    //         name: 'dateOfBirth',
    //         value: getHTMLdateFormat(dob)
    //     }
    // })
    // dispatch({
    //     type: 'CHANE_STATE', payload: {
    //         name: 'address',
    //         value: address
    //     }
    // })
    if (!state?.phone) {
      setError();
    } else if (!state?.phone) {
      setError(t('error_enter_phone'));
    } 
    // else if (!dob) {
    //   setError(t('error_Select_date_of_birth'));
    // } 
    else if (!state?.gamerjiName) {
      setError(t('error_enter_gamerji_username'));
    } 
    // else if (!state?.avatar) {
    //   setError();
    // } 
    else {
      try {
        func("payload", payload);
        setIsLoading(true);
        eventTracking(events.profile_edit_submit,{
          OP_CountryCode:userData?.country?.dialingCode,
          OP_MobileNumber:state?.phone,
          OP_State:stateId,
          OP_DOB:dob,
          OP_Email:emailValue,
          OP_Username:state?.gamerjiName,
          OP_Avatar:avatarId
       });
        const res = await requestUpdateProfile(payload);
        if (res) {
          func1("result", res);
          toast.success(res?.msg);
          setShow(false);
          setEditProfile(false);

          // window.location.href = "/" + routes.home + "/" + routes.myProfile;
        }
      } catch (error) {
        func("error", error?.response?.data?.errors[0]?.msg);
        if (
          error?.response?.data?.errors?.[0]?.code ==
            "middlewares.token_parser.validation.token_invalid" ||
          error?.response?.data?.errors?.[0]?.code ==
            "middlewares.token_parser.validation.token_expired"
        )
          logout();
        // setError(error?.response?.data?.errors[0]?.msg);
        toast.error(error?.response?.data?.errors[0]?.msg);
      }
      setIsLoading(false);
    }

    func("sdfvdhsbv", state);
  };
  return (
    <>
    {isLoading ? (
      <Loader />
    ) : (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="auth-modal modal fade "
        centered
      >
        <Modal.Body>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <form
            className="row justify-content-center "
            style={{ rowGap: "20px" }}
          >
            <div class="col-md-6 justify-content-center align-items-center">
              <h3 class="bodyBoldFont mb-5 text-center">{t('Profile.edit')}</h3>
              <div className="row d11-profile-form mb-3">
                <div className="col-4">
                  <label
                    for="inputCoutryCode"
                    className="form-label text-nowrap "
                  >
                  {t('Profile.country_code')}
                  </label>
                  {/* <select
                                              id="inputCoutryCode"
                                              className="form-select select d11-phoneCode"
                                              name='phoneCode'
                                              value={state?.phoneCode}
                                              disabled={state?.phoneCode}
                                        
                                          >
                                              <option>{state?.phoneCode}</option>

                                          </select> */}
                  <div
                    class="input-group w-auto d11-phoneCode form-select select"
                    style={{ backgroundColor: "#0000003b" ,    border: "0.13rem solid var(--color-gray)"}}
                  >
                    <img
                      className="icon-24"
                      style={{ borderRadius: "50%" }}
                      src={
                        url.imageUrl +
                        userData?.country
                          ?.flag?.default
                      }
                      alt=""
                    />

                    <div
                      name=""
                      id=""
                      // class="form-control form-control-sm  "
                      style={{
                        maxWidth: "50px",
                        alignItems: "center",
                        padding: " 0 5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      disabled={true}
                    >
                      {state?.phoneCode}
                      {/* {countryList?.length > 0 ? (
                                countryList?.map((ele, index) => (
                                  <option>
                                    <img
                                      className="country_img"
                                      src={url.imageUrl + ele?.flag?.default}
                                      alt={ele?.name}
                                    />
                                    {ele?.dialingCode}
                                  </option>
                                ))
                              ) : (
                                <></>
                              )} */}
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <label for="inputMobileNumber" className="form-label">
                  {t('Profile.mobile_number')}
                  </label>
                  <input
                    type="number"
                    className="form-control "
                    id="inputMobileNumber"
                    placeholder={t('login.enter_mobile_number')}
                    name="mobileNumber"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) &&
                      e.preventDefault()
                    }
                    onChange={(e) => handlerChange(e)}
                    disabled={state?.phone ? true : false}
                    maxLength={14}
                    value={state?.phone}
                  />
                </div>
              </div>
              <div className="row">
                {/* {state?.phoneCode === "+91" ? (
                  <div className="col-12 d11-profile-edit">
                    <label for="inputMobileNumber" className="form-label">
                      {t('Profile.state')}
                    </label> */}
                    {/* <input
                              type="number"
                              className="form-control"
                              id="inputMobileNumber"
                              placeholder="Enter Code"
                              name='phone'

                              // onChange={(e) => setCodeVal(e.target.value)}
                              maxLength={14}
                          /> */}
                    {/* <select
                      id="inputCoutryCode"
                      className="form-select select d11-phoneCode"
                      name="address"
                      //    value={stateValue}
                      disabled={state?.address?.state ? true : false}
                      onChange={(e) => {
                        func("dvStataid", stateList[e.target.value]?.name);
                        setStateId(stateList[e.target.value]?._id);
                        setStateValue(stateList[e.target.value]?.name);
                      }}
                    > */}
                      {/* {state?.address?.state ? (
                        <option value="">{stateValue}</option>
                      ) : (
                        <>
                          {/* <option value="">{stateList[0]?.name}</option> */}
                          {/* {stateList.map((ele, ind) => (
                            <option value={ind}>{ele?.name}</option>
                          ))}
                        </> */}
                      {/* )} */}
                    {/* </select>
                  </div>
                ) : (
                  ""
                )} */}

                {/* <div className="col-12 d11-profile-edit">
                  <label for="inputMobileNumber" className="form-label">
                    {t('Profile.date_of_birth')}
                  </label>
                  {func("dovvbb", getHTMLdateFormat(dob))} */}
                  {/* <input
                    type="date"
                    max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                    className="form-control"
                    id="inputMobileNumber"
                    placeholder="Enter Code"
                    name="dateOfBirth"
                    value={getHTMLdateFormat(dob)}
                    onChange={(e) => {
                      setDob(e.target.value);
                      // handlerChange(e)
                    }}
                    disabled={state?.dateOfBirth}
                    maxLength={14}
                  /> */}
                  {/* <DatePicker
                
                name="dateOfBirth"
                value={dob}
                disabled={state?.dateOfBirth}
                handlerChange={(e) => {
                  setDob(e.format());
                }}
              /> */}
                {/* </div> */}

                {/* <div className="col-12 d11-profile-edit">
                  <label for="inputMobileNumber" className="form-label">
                    {t('Profile.email')}
                  </label>

                  {console.log(userData?.item?.isEmailVerified)}
                  <input
                    type="text"
                    className="form-control"
                    id="inputMobileNumber"
                    placeholder={t('Profile.enter_your_email_id')}
                    name="email"
                    value={emailValue}
                    disabled={profileData?.item?.isEmailVerified ? true : false}
                    onChange={(e) => {
                      //  handlerChange(e)
                      func("emailvalue", e.target.value);
                      setEmailValue(e.target.value);
                    }}
                  />
                </div> */}
                <div className="col-12 d11-profile-edit">
                  <label for="inputMobileNumber" className="form-label">
                    {t('tournaments.username')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputMobileNumber"
                    placeholder={t('Profile.enter_mobily_name')}
                    name="gamerjiName"
                    value={state?.gamerjiName}
                    onChange={(e) => handlerChange(e)}
                    disabled={state?.gamerjiName ? true : false}
                    maxLength={14}
                  />
                </div>
              </div>
              {/* <div class="row justify-content-center avatarModal mb-3">
                <div class="col-12">
                  <div class="radioBlock">
                    <div class="radioInner">
                      {/* {avatarList?.map((item, index) => (
                        <div
                          class="form-check"
                          key={index}
                          // onClick={() => {
                          //     if(localStorage.getItem("planName") ==
                          //     "Grandmaster" ||  item?.isBuy )
                          //   onClickHandler(item);
                          // }}
                        >
                          <input
                            class="form-check-input edit-dis"
                            type="radio"
                            name="avatar"
                            id={`radio${index}`}
                            checked={avatarId === item?._id ? true : false}
                            //   value={avatarId}
                            onChange={(e) => {
                              func("clickedd**************");
                              if (
                                premiumUser &&
                                localStorage.getItem("planName") ==
                                "Grandmaster"
                              )
                                setAvatarId(item?._id);
                              else if (item?.isBuy) {
                                // console.log( item?.isBuy);
                                setAvatarId(item?._id);
                              }
                              // else setAvatarId("");
                            }}
                            disabled={
                              localStorage.getItem("planName") !==
                              "Grandmaster" && !item?.isBuy
                            }
                          />
                          <label
                            class="form-check-label "
                            for={`radio${index}`}
                            style={{ fontSize: "1rem" }}
                          >
                            <img
                              src={url.imageUrl + item?.img?.default}
                              alt=""
                              style={{
                                width: "80px",
                                height: "140px",
                                zIndex: "1500",
                              }}
                            />
                            {/* {func("DIDDDDDDDDD",localStorage.getItem("planName") !=="Grandmaster")} */}
                            {/* {avatarId === item?._id ? (
                              <></>
                            ) : localStorage.getItem("planName") !==
                            "Grandmaster" && !item?.isBuy ? (
                              <div className="ps_avatar_op">
                                <img src={Lock} />
                              </div>
                            ) : (
                              item?.isPremium && (
                                <div
                                  className="ps_avatar_op"
                                  style={{
                                    backgroundColor: "transparent",
                                    rotate: "22",
                                  }}
                                >
                                                                    {premiumUser ? (
                                    localStorage.getItem("planName") ==
                                    "Champion" ? (
                                        ""
                                      ) : (
                                      <img
                                        style={{
                                          height: "30px",
                                          width: "60px",
                                          left: "17%",
                                          top: "5%",
                                        }}
                                        src={Master}
                                      />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )
                            )} */}

                            {/* <span class="d-block mt-2">{item?.name}</span>
                          </label>
                        </div>
                      ))} 
                    </div>
                    {
                      // avatarError && <p className='error'>{avatarError}</p>
                    }
                  </div>
                </div>
              </div> */}
              {/* <div className="ps_summary text-center">
                {premiumUser &&
                localStorage.getItem("planName") == "Grandmaster" ? (
                  <p>
                  <Trans
                    i18nKey="Profile.you_have_unlock_avatar"
                    t={t}
                    components={[<Link className="link" to={"/home/" + routes.getSubscriptionTypeList}> {t('premium.mobily_premium')} </Link>]}
                  />
                  </p>
                ) : (
                  <p>
                  <Trans
                    i18nKey="Profile.unlock_avatar"
                    t={t}
                    components={[<Link className="link" to={"/home/" + routes.getSubscriptionTypeList}> {t('premium.mobily_premium')} </Link>]}
                  />
                  </p>
                )}
              </div> */}
              <div className="col-12 d-grid">
                <button
                  className="btn btn-primary btn-arrow"
                  // type="submit"
                  onClick={(e) => {
                    func("evalue", e);
                    e.preventDefault();
                    handlerSubmit(e);
                  }}
                >
                  {t('right_hand_drop_down.save')}
                </button>
                {error && (
                  <p className="error" style={{ textAlign: "center" }}>
                    {error}
                  </p>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )}
  </>
  );
};

export default EditProfile;
