import React from "react";
import LeftArrow from "../../assets/images/left-arrow.png";
import RightArrow from "../../assets/images/right-arrow.png";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="d11-pagination">
      <button
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <img src={LeftArrow} alt="Previous Page" />
      </button>
      <ul className="pagination mb-0">
        {pageNumbers.map((page) => {
          if (
            page <= 4 ||
            page > totalPages - 4 ||
            (page >= currentPage - 2 && page <= currentPage + 2)
          ) {
            return (
              <li key={page} className={currentPage === page ? "active" : ""}>
                <button onClick={() => onPageChange(page)}>{page}</button>
              </li>
            );
          }
          return null;
        })}
      </ul>
      <button
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <img src={RightArrow} alt="Next Page" />
      </button>
    </div>
  );
};

export default Pagination;
