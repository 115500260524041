import React, { useEffect, useState } from "react";
import CustomerCareForm from "./CustomerCareForm";
import { useLocation, useNavigate } from "react-router";
import { func } from "../../../Utilities/logFunc";
import TicketNumber from "../../../assets/images/ticketNimber-icon-svg.svg";

import Calender from "../../../assets/images/calendar-icon-svg.svg";
import Status from "../../../assets/images/status-icon-svg.svg";
import { Link } from "react-router-dom";
import { routes } from "../../../Utilities/routesFb";
import { url } from "../../../Utilities/url";
import { getTicket } from "../../../Utilities/controller";
import Loader from "../../Common/Loader";
import TicketIcon from '../../../assets/images/ticketNimber-icon-svg.svg'
import TelephoneIcon from '../../../assets/images/telephone-svgrepo-com.svg'

import moment from "moment";
import Pagination from "../../Common/Pagination";
import NonPremiumCRMModal from "../../Subscription/NonPremiumCRMModal";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";

const CustomerCare = () => {
  const location = useLocation();
  // const { active } = location.state;
  // func(active, "lead");
  const [ticketData, setTicketData] = useState([]);
  const [skipCount, setSkipCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate()
  const premiumUser = JSON.parse(localStorage.getItem("isUserSubscribed"))
  const [showPremiumPopup, setShowPremiumPopup] = useState(false)
  const { t } = useTranslation();

  const fetchTicket = async (skipCount) => {
    setLoading(true);
    func("skip count", skipCount);
    try {
      let payload = {
        skip: skipCount,
        limit: 4,
      };
      const res = await getTicket(payload);
      if (res?.data?.list) {
        setTicketData(res?.data?.list);
        setTotalRecords(res?.data?.count);
        setTotalPages(Math.ceil(res?.data?.count / 4));
        setLoading(false);
      }
    } catch (error) {
      func("error", error);
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSkipCount((prev) => (page - 1) * 4);
    fetchTicket((page - 1) * 4);
  };
  useEffect(() => {
    func("called");
    fetchTicket(0);
    // if(!premiumUser)
    // setShowPremiumPopup(true)
  }, []);

  const getTicketStatus = (ticket) => {
    if (ticket?.status) {
      switch (ticket?.status) {
        case "1":
          return "PENDING";
        case "2":
          return "REPLIED";
        case "3":
          return "IN PROGRESS";
        case "4":
          return "APPROVED";
        case "5":
          return "REJECTED";
        case "6":
          return "AUTO APPROVED";
        default:
          break;
      }
    } else {
      return "PENDING";
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div style={{position:'relative', paddingBottom:'40px'}}>
      <div class="row gy-5">
        {/* <CustomerCareForm /> */}
        <div class="col-12">
          <div class="mb-5 pb-3 row justify-content-between align-items-center">
            <div class="col">
              <h2 class="h3 bodyExtraBoldFont mb-0">{t("crm.your_complaints")}</h2>
            </div>
            <div class="col-xxl-3 col-xl-4 col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="d-grid">
                <Link
                  to={routes.raiseACompaint}
                  class="btn btn-arrow btn-blue"
                >
                  {t("crm.raise_a_complaint")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-4">
        {ticketData.length > 0 ? (
          ticketData.map((ele, ind) => (
            <div class="col-xxl-3 col-lg-4 col-md-6" key={ind}>
              <div class="card complaintCard">
                <div class="card-body" onClick = {()=> {navigate('ticket-details/' + ele?._id, {state:ele?.callTime});
                eventTracking(events.ticket_box,{
                  OD_TicketID:ele?._id
                });
              }}>
                  {
                    premiumUser && ele?.callTime ? 
                    <div class="row g-2 align-items-stretch">
                    <div class="col-3" 
                    // style={{paddingLeft:'12px', paddingRight:'0.25rem'}}
                    >
                      <div class="d-flex flex-column h-100 align-items-center bg-primary rounded-2 text-center py-1 px-1 ">
                        <div class="icon mb-1">
                          <img class="icon-20" src={TicketNumber} alt="" />
                        </div>
                        {/* <div style={{width:'100%'}}> */}
                        <h6 class="fs-7 mb-1 bodyNormalFont text-truncate w-100" 
                        // style={{display:'block',width:'60px',whiteSpace: "nowrap", overflow: "hidden",textOverflow: "ellipsis"}} 
  >{t("crm.ticket_number")}</h6>
                        {/* </div> */}
                        <h6 class="fs-6 mb-0 bodyNormalFont font-weight-bold">{ele?.code}</h6>
                      </div>
                    </div>
                    <div class="col-3 " 
                    // style={{paddingLeft:'10px'}}
                    >
                      <div class="d-flex flex-column h-100 align-items-center bg-primary rounded-2 text-center py-1 px-1 ">
                        <div class="icon mb-1">
                          <img class="icon-20" src={Calender} alt="" />
                        </div>
                        <h6 class="fs-7 mb-1 bodyNormalFont">{t("crm.date")}</h6>
                        <h6 class="fs-6 mb-0 bodyNormalFont font-weight-bold">
                          {moment(ele?.createdAt).format("DD/MM/YYYY")}
                        </h6>
                      </div>
                    </div>
                    <div class="col-3 ">
                      <div class="d-flex flex-column h-100 align-items-center bg-primary rounded-2 text-center py-1 px-1 ">
                        <div class="icon mb-1">
                          <img class="icon-20" src={TelephoneIcon} alt="" />
                        </div>
                        <h6 class="fs-7 mb-1 bodyNormalFont">{t("crm.call_back")}</h6>
                        <h6 class="fs-65 mb-0 bodyNormalFont font-weight-bold">
                          {ele?.callTime}
                        </h6>
                      </div>
                    </div>
                    <div class="col-3 " 
                    // style={{paddingRight:'12px', paddingLeft:'0.25rem'}}
                    >
                      <div class="d-flex flex-column h-100 align-items-center bg-primary rounded-2 text-center py-1 px-1 ">
                        <div class="icon mb-1">
                          <img class="icon-20" src={Status} alt="" />
                        </div>
                        <h6 class="fs-7 mb-1 bodyNormalFont">{t("crm.status")}</h6>
                        <h6 class="fs-6 mb-0 bodyNormalFont font-weight-bold text-truncate w-100" 
                        // style={{display:'block',width:'60px',whiteSpace: "nowrap", overflow: "hidden",textOverflow: "ellipsis"}}
  >
                          {
                          // getTicketStatus(ele)?.length > 8 ? (
                          //   <marquee>{getTicketStatus(ele)}</marquee>
                          // ) : (
                            getTicketStatus(ele)
                          // )
                          }
                        </h6>
                      </div>
                    </div>
                    <div class="col-12" style={{ height: "50px" }} >
                      <div class="d-flex flex-column h-100 bg-color-black rounded-bottom-2 p-2 ">
                        <h6 class="fs-6 mb-2 bodyNormalFont font-weight-bold">{t("crm.subject")}: {ele?.category?.name}</h6>
                        <h6 class="fs-6 mb-0 bodyNormalFont d11-ticket-text">
                          {ele?.description}
                        </h6>
                        {/* {ele?.screenShot?.default && (
                        <img
                          src={url?.imageUrl + ele?.screenShot?.default}
                          alt=""
                          class="img-fluid rounded-1"
                        />
                      )} */}
                      </div>
                    </div>
                  </div> :
                  <div class="row g-2 align-items-stretch">
                  <div class="col-4">
                    <div class="d-flex flex-column h-100 align-items-center bg-primary rounded-2 text-center py-1 px-2 ">
                      <div class="icon mb-1">
                        <img class="icon-20" src={TicketNumber} alt="" />
                      </div>
                      <h6 class="fs-7 mb-1 bodyNormalFont text-truncate w-100" 
                      // style={{display:'block',width:'80px',whiteSpace: "nowrap", overflow: "hidden",textOverflow: "ellipsis"}} 
  >
    {t("crm.ticket_number")}
    </h6>
                      <h6 class="fs-6 mb-0 bodyNormalFont font-weight-bold">{ele?.code}</h6>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="d-flex flex-column h-100 align-items-center bg-primary rounded-2 text-center py-1 px-2 ">
                      <div class="icon mb-1">
                        <img class="icon-20" src={Calender} alt="" />
                      </div>
                      <h6 class="fs-7 mb-1 bodyNormalFont">{t("crm.date")}</h6>
                      <h6 class="fs-6 mb-0 bodyNormalFont font-weight-bold">
                        {moment(ele?.createdAt).format("DD/MM/YYYY")}
                      </h6>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="d-flex flex-column h-100 align-items-center bg-primary rounded-2 text-center py-1 px-2 ">
                      <div class="icon mb-1">
                        <img class="icon-20" src={Status} alt="" />
                      </div>
                      <h6 class="fs-7 mb-1 bodyNormalFont" >{t("crm.status")}</h6>
                      <h6 class="fs-6 mb-0 bodyNormalFont font-weight-bold text-truncate w-100" 
                      // style={{display:'block',width:'80px',whiteSpace: "nowrap", overflow: "hidden",textOverflow: "ellipsis"}}
  >
                        {
                        // getTicketStatus(ele)?.length > 8 ? (
                        //   <marquee>{getTicketStatus(ele)}</marquee>
                        // ) : (
                          getTicketStatus(ele)
                        // )
                        }
                      </h6>
                    </div>
                  </div>
                  <div class="col-12" style={{ height: "50px" }} onClick = {()=> navigate('ticket-details/' + ele?._id)}>
                    <div class="d-flex flex-column h-100 bg-color-black rounded-bottom-2 p-2 ">
                      <h6 class="fs-6 mb-2 bodyNormalFont font-weight-bold"> {t("crm.subject")}: {ele?.category?.name}</h6>
                      <h6 class="fs-6 mb-0 bodyNormalFont d11-ticket-text">
                        {ele?.description}
                      </h6>
                      {/* {ele?.screenShot?.default && (
                      <img
                        src={url?.imageUrl + ele?.screenShot?.default}
                        alt=""
                        class="img-fluid rounded-1"
                      />
                    )} */}
                    </div>
                  </div>
                </div>
                  }
                  
                </div>
              </div>
            </div>
          ))
        ) : (
          <div class="row justify-content-center">
            <div
              class="col-12 justify-content-center align-items-center"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <img
                style={{width:'100px'}}
                src={TicketIcon}
                alt=""
                class="img-fluid rounded-1"
              />
              <h3 class="bodyBoldFont text-center"> {t("notFound.no_ticket_found")}</h3>
              <h6 class="bodyBoldFont mb-5 text-center">
              {t("crm.look_like_not_complaint")}
              </h6>
            </div>
          </div>
        )}
      </div>
      {console.log("currentPage: ",currentPage,"totalPages: ", totalPages)}
      {totalPages > 1 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      ) : (
        ""
      )}
      {
        showPremiumPopup && <NonPremiumCRMModal showModal={showPremiumPopup} setShowModal={setShowPremiumPopup} />
      }
    </div>
  );
};

export default CustomerCare;
