import React from 'react'
import BaseModalSubscription from './BaseModalSubscription'
import { Link, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from "react-i18next";

const GameInsights = ({showModal,setShowModal}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const buttonHandler = ()=>{
    setShowModal(false)
    navigate('/home/get-premium')
  }
  const handlerClose = ()=>{
    setShowModal(false)
  
  }
  return (
   
    <BaseModalSubscription text={t('premium.get_mobily_premium')} showModal={showModal} setShowModal={setShowModal} buttonHandler={buttonHandler} handlerClose={handlerClose}>
        <h6 className='mt-3 d11-premium-h6'> 
        <Trans
            i18nKey="premium.as_a_mobily_user_you_will_be_able_to_unlock_view_more_insights_regarding_your_gameplay"
            t={t}
            components={[<Link to={"/home/get-premium"} style={{ color: "var(--primary-color-dark)" }}> {t('premium.mobily_premium')} </Link>]}
          />
          </h6>
     
          <h6 className='d11-premium-h6'>{t('premium.premium_users_get_to_join_unlimited_contests_tournaments_for_free_and_many_more_other_benefits')}</h6>
      
    </BaseModalSubscription>
  )
}

export default GameInsights