export const events = {
  login_with_phone: "login_with_phone",
  leaderboard_menu: "leaderboard_menu",
  crm_menu: "crm_menu",
  premium_menu: "premium_menu",
  all_games_menu:"all_games_menu",
  
  banners: "banners",
  gamenamebox:"gamenamebox",
  featured_tournaments: "featured_tournaments",

  my_profile:"my_profile",
  join_via_invite_code:"join_via_invite_code",
  oreedo_point:"oreedo_point",
  legality:"legality",
  logout: "logout",

  change_pin:"change_pin",
  Terms_of_use_Tab:"Terms_of_use_Tab",
  Privacy_Policy_Tab:"Privacy_Policy_Tab",
  referral_code_copy:"referral_code_copy",
  Refer :"Refer",
  daily_login_reward:"daily_login_reward",
  join_via_invite_submit:"join_via_invite_submit",
  View_Insights:"View_Insights",
  profile_edit:"profile_edit",
  profile_edit_submit : "profile_edit_submit",
   
  premium_pop_up:"premium_pop_up",
  raise_a_complaint:"raise_a_complaint",
  ticket_box:"ticket_box",
  subcription_plan:"subcription_plan",
  subscription_purchase:"subscription_purchase",
  payment_status_popup:"payment_status_popup",
  free_tournamnet_non_premium:"free_tournamnet_non_premium",
  free_contest_non_premium:"free_contest_non_premium",
  select_call_back_time:"select_call_back_time",
  schedule_call:"schedule_call",
  search :"search",
  today_tab : "today_tab",
  weekly_tab:"weekly_tab",
  monthly_tab:"monthly_tab",
  all_tab:"all_tab",
  tournament_room_id:"tournament_room_id",
  next:"next",
  tournament_passsword:"tournament_passsword",
  tournamnet_winners:"tournamnet_winners",
  join_tournament:"join_tournament",
  tournament_timing_selection:"tournament_timing_selection",

  tournament_rules:"tournament_rules",

tournament_join_now:"tournament_join_now",
contest_passsword:"contest_passsword",
contest_room_id:"contest_room_id",

contest_winners:"contest_winners",
join_contests:"join_contests",
contest_join_now:"contest_join_now",

my_contests:"my_contests",
chat:"chat",
change_username_AddUpdate:"change_username_AddUpdate",
change_username:"change_username",
tournaments:"tournaments",
how_to_join:"how_to_join",
request_forgot_pin_otp:"request_forgot_pin_otp",
verify_forgot_pin_otp:"verify_forgot_pin_otp",

};
