import React, { useEffect, useReducer, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { Outlet } from 'react-router'
import { func } from '../../Utilities/logFunc'
import { isUserPremium } from '../../Utilities/controller'

import { toast } from 'react-toastify'
import ProfileDataProvider from './ProfileContext'


const HomePage = () => {
const [activeNav, setActiveNav]= useState('')
// localStorage.setItem("premiumUser",true)
const [isActiveMenu, seActiveMenu] = useState(false)
console.log("Home Page")

  return (
    <ProfileDataProvider>
    <main class="dashboardMain">
      <Sidebar setActiveNav={setActiveNav} seActiveMenu={seActiveMenu} isActiveMenu={isActiveMenu}/>

      <Navbar  activeNav={activeNav} seActiveMenu={seActiveMenu} isActiveMenu={isActiveMenu} />
      <Outlet />
    </main>
    </ProfileDataProvider>
  )
}

export default HomePage