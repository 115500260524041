import React from 'react'

import heroimage from "../LandingPage/images/glo_hero.png"
const Main = () => {
  return (
    <main className="d-grid align-content-center" style={{backgroundColor:"var(--color-white)"}}>
      {/* <div className="container mainhero_section">
        <div className='mt-5'>
        <h1 className="mb-3">WELCOME TO POWERPLAY GAMING. <br />YOUR GAMING HQ.</h1>
        <p>Your first step to becoming a gamer!</p>
        </div>
        <div className=''>
          <img src={heroimage} className='mainheroImg' alt="" />
        </div>
      </div> */}
    </main>
  )
}

export default Main