import React from "react";
import { url } from "../../../Utilities/url";
import moment from "moment";
import { func } from "../../../Utilities/logFunc";
import { json, useNavigate } from "react-router";
import DownArrow from "../../../assets/images/downArrow-svg.svg";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import { useTranslation } from "react-i18next";
const TournamentStructure = ({
  handlerJoin,
  handlerWinning,
  tournaments,
  setShowRulesModal,
  setSelectedTournament,
  setShowJoinModal,
  setShowTournamentModal,
  setShowJVC
}) => {
  const remainingPlayers =
    tournaments?.totalPlayers - tournaments?.totalJoinedPlayers;

  const findPercentage = (data) => {
    let totalPlayer = data?.totalPlayers || 0;
    let totalPlayerJoined = data?.totalJoinedPlayers || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage + "%";
  };

  const navigate = useNavigate();
  const {t} = useTranslation()

  const handlerJoinTournament = () => {
    setShowJoinModal(false);
    if(setShowJVC) setShowJVC(false)
    navigate("/home/tournament-details/" + tournaments?._id);
  };

  return (
    <div className="card tournametParticipationCard">
      <div className="card-header" >
        <h4 className="card-title mb-0">{tournaments?.title?.length > 30 ? <marquee>{tournaments?.title}</marquee> : tournaments?.title}</h4>
        <span
          className="rulesLink"
          onClick={() => {
            func("rules");
            setShowRulesModal(true);
            setSelectedTournament(tournaments);
          }}
        >
           {t('tournaments.view_rules')}
        </span>
      </div>
      <div className="card-body">
        <div className="row justify-content-md-start justify-content-center">
          <div className="col-md-3 col-sm-5 col-6 pe-md-0" 
          // style={{paddingRight:'0'}}
          >
            <div
              className="card-img"
              // style={{ width: "100%", height: "240px" }}
            >
              {tournaments?.featuredImage?.default ? (
                <img
                  // style={{ width: "100%" }}
                  src={url.imageUrl + tournaments?.featuredImage?.default}
                  alt=""
                  className="w-100 rounded-1 height-180"
                />
              ) : (
                <img
                  src={url.imageUrl + tournaments?.featuredImage?.default}
                  alt=""
                  className="w-100 rounded-1 height-240"
                />
              )}
              <div className="img-title text-uppercase bodyHeavyFont w-100 text-center h4 mb-0">
                {tournaments?.gameTypeAry?.name}
              </div>
            </div>
          </div>
          <div className="col-md-9 col-12 mt-md-0 mt-5">
            <div className="card-content">
              <div className="row justify-content-between py-2 ps-3">
                <div className="col-4">
                  <label for="" className="d-block h5">
                  {t('tournaments.date')}
                  </label>
                  <h5 className="bodyBoldFont mb-0">
                    {moment(tournaments?.contestsList?.[0]?.date).format(
                      "D/M/YYYY"
                    )}
                  </h5>
                </div>
                <div className="col-4 dividersX dividersWhite">
                  <label for="" className="d-block h5">
                  {t('tournaments.rounds')}
                  </label>
                  <h5 className="bodyBoldFont mb-0">
                    {tournaments?.rounds?.length}
                  </h5>
                </div>
                <div className="col-4">
                  <label for="" className="d-block h5">
                  {t('tournaments.id')}
                  </label>
                  <h5
                    className="bodyBoldFont mb-0"
                    onClick={() => {
                      navigator.clipboard.writeText(tournaments?.shortCode);
                      toast.info(t('info_Copied'), {
                        // position: "top-left",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }}
                  >
                    {tournaments?.shortCode}
                  </h5>
                </div>
              </div>
              <div className="bg-primary-dark py-2 mt-1">
                <div className="row">
                  <div
                    className={`col-6 ${tournaments?.titles?.[1]?.value ? "dividersRight" : ""}  ps-4`}
                    // style={{ paddingLeft: "40px" }}
                  >
                    <label for="" className="d-block h5 text-ellipsis_contest" style={{color:"var(--color-black)"}}>
                      {
                      // tournaments?.titles?.[0]?.name?.length > 15
                      //   ? tournaments?.titles?.[0]?.name?.substring(0, 15 - 3) +
                      //     "..."
                      //   : 
                        tournaments?.titles?.[0]?.name
                        }
                      {/* {tournaments?.titles[0]?.name} */}
                    </label>
                    <h5 className="bodyBoldFont mb-0 text-ellipsis_contest" style={{color:"var(--color-black)",fontWeight:"600" ,fontSize:"1.2rem"}}>
                      {
                      // tournaments?.titles?.[0]?.value?.length > 15
                      //   ? tournaments?.titles?.[0]?.value?.substring(0, 15 - 3) +
                      //     "..."
                      //   : 
                        tournaments?.titles?.[0]?.value
                        }
                      {/* {tournaments?.titles[0]?.value} */}
                    </h5>
                  </div>
                  {
                     tournaments?.titles?.[1]?.value && 
                     <div className="col-6">
                     <label for="" className="d-block h5 text-ellipsis_contest" style={{color:"var(--color-black)"}}>
                       {/* {tournaments?.titles?.[1]?.name?.length > 15
                         ? tournaments?.titles[1]?.name?.substring(0, 15 - 3) +
                           "..."
                         : */}
                         { tournaments?.titles?.[1]?.name}
                         {/* // } */}
                       {/* {tournaments?.titles[1]?.name} */}
                     </label>
                     <h5 className="bodyBoldFont mb-0 text-ellipsis_contest" style={{color:"var(--color-black)",fontWeight:"600",fontSize:"1.2rem"}}>
                       {
                       // tournaments?.titles?.[1]?.value?.length > 15
                       //   ? tournaments?.titles?.[1]?.value?.substring(0, 15 - 3) +
                       //     "..."
                       //   : 
                         tournaments?.titles?.[1]?.value
                         }
                       {/* {tournaments?.titles[1]?.value} */}
                     </h5>
                   </div>
                  }
                
                </div>
              </div>
              
              <div className="row mt-2">
                <div className="col-12">
                  <div
                    className="progress"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className="progress-bar bg-info"
                      style={{ width: findPercentage(tournaments) }}
                    ></div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <h5>{t('tournaments.players_remaining',{players:remainingPlayers})}</h5>
                    <h5>{t('tournaments.players_joined',{players:tournaments?.totalJoinedPlayers})}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { tournaments?.totalPlayers - tournaments?.totalJoinedPlayers > 0 ?
      <div className="card-footer d-grid">
        {
        tournaments?.isJoined == false ? (
          <button
            className="btn btn-primary"
            onClick={() => handlerJoin(tournaments)}
          >
            {t('tournaments.join_now')}
          </button>
        )
         : (
          <button className="btn btn-primary" onClick={handlerJoinTournament}>
             {t('tournaments.joined')}
          </button>
        )}
      </div>
      :
    ((tournaments?.isJoined == true && remainingPlayers == 0) ?
      (
        <button className="btn btn-primary" onClick={handlerJoinTournament}>
           {t('tournaments.joined')}
        </button>
      )
      :(
        <div className="card-footer d-grid">
        <button
          class="btn btn-primary"
          // onClick={() =>
          //   navigate("/home/contest-details/" + tournaments?._id)
          // }
        >
          {t('tournaments.full')}
        </button>
    </div>
      )
      )
      }
    </div>
  );
};

export default TournamentStructure;
