import React, { useEffect, useState } from "react";
import ProfileBG from "../../assets/images/profileBgNew.jpg";
import DailyLoginRewards from "./DailyLoginRewards";
import { func } from "../../Utilities/logFunc";
import {
  collegeList,
  getAccountProfile,
  getHeaderApi,
  getStatistics,
  requestUpdateProfile,
  favGameList,
  getGamePlayed,
  unSubscription
} from "../../Utilities/controller";
import { url } from "../../Utilities/url";
import LevelsModal from "./LevelsModal";
import EditProfile from "./EditProfile";
import Loader from "../Common/Loader";
import Select from "react-select";
import ChartImg from "../../assets/images/charts.png";
import CollegeCard from "./CollegeCard";
import CollegeModal from "./CollegeModal";
import { toast, ToastContainer } from "react-toastify";
import { routes } from "../../Utilities/routesFb";
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Tooltip } from "react-bootstrap";
import GameInsightsChart from "../Subscription/GameInsightsChart";
import GameInsights from "../Subscription/GameInsightsSubscriptionModal";
import {
  useProfileDataContext,
  useuserDataContext,
} from "../HomePage/ProfileContext";
import { RWebShare } from "react-web-share";
import { Link } from "react-router-dom";
// import Champion from "../../assets/images/dGames-champion.png";
// import Master from "../../assets/images/dGames-master.png";
import premiumIcon from '../../assets/images/gamerji-coin.svg'
import { logout } from "../../Utilities/logout";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const MyProfile = () => {
  const colors = [
    "#509E2F",
    "linear-gradient(181deg, #509E2F 100%, #509E2F 0%)",
    "#509E2F",
  ];
  let premiumUser = JSON.parse(localStorage.getItem("isUserSubscribed"));
  let isUserSubscribed = JSON.parse(localStorage.getItem("isUserSubscribed"));
  const { t } = useTranslation();
  const [userData, setUserData] = useState([]);
  const [levelProgressBar, setLevelProgressBar] = useState(0);
  const [showLevel, setShowLevel] = useState(false);
  const [showEditProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCollegeDetails, sethowCollegeDetails] = useState(false);
  const [collegeListData, setCollegeList] = useState([]);
  const [collegeValue, setCollegeValue] = useState("");
  const [searchValue, setSearchValue] = useState();
  const [statisticsVal, setStatisticsVal] = useState([]);
  const [changeDailyRewrds, setDailyRewards] = useState(false);
  const [showInsights, setShowInsights] = useState(false);
  const [showNonSubscribeInsights, setShowNonSubscribeInsights] =
    useState(false);
  const [gameId, setgameId] = useState("");
  const [gameName, setGameName] = useState("");
  // const { userData, updateuserData } = useuserDataContext();
  const { profileData, updateProfileData } = useProfileDataContext();
  // const [buttonLoader, setButtonLoader] = useState(false);

  const handleClose = () => {
    setDailyRewards(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [showNonSubscribeInsights])


  const fetchAccountProfile = async () => {
    try {
      const data = await getAccountProfile();

      setUserData(data?.data);
      func("profile data", data?.data);
      // localStorage.setItem("profile", JSON.stringify(data?.data));
      if (data) {
        let currentLevelPoints =
          data?.data?.item?.level?.level?.endPoint -
          (data?.data?.item.level?.level?.startPoint - 1);

        let userPointsAsPerLevel =
          data?.data?.item.level?.points -
          (data?.data?.item.level?.level?.startPoint - 1);

        let lpVal = (userPointsAsPerLevel * 100) / currentLevelPoints;
        // console.log("lpVal===>", lpVal)
        const resProfile = await getHeaderApi();
        updateProfileData(resProfile?.data);
        setLevelProgressBar(lpVal);
        // getStatisticsList();
        // if (!data?.data?.college?.name) getCollegeList();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getCollegeList = async () => {
    try {
      let payload = {
        sort: "desc",
        skip: collegeListData?.length,
        limit: 10,
        sortBy: "createdAt",
        q: searchValue,
      };
      const res = await collegeList(payload);
      setCollegeList([...collegeListData, ...res?.data?.list]);
      func("college", res?.data);
    } catch (error) {
      setLoading(false);
      func("error", error);
    }
  };
  const getFavoriteGameList = async () => {
    try {
      let data = {
        pagination: {
          pageNo: 1,
          recordPerPage: 100,
          sortBy: "order",
          sortDirection: "asc",
        },
      };
      const res = await favGameList(data);

      let allGames = res?.data?.data;
      var tempArr = [];

      for (let index = 0; index < allGames.length; index++) {
        var element = { ...allGames[index] };

        tempArr.push(element?._id);
      }
      return tempArr;
    } catch (error) {
      return [];
    }
  };
  const getStatisticsList = async () => {
    try {
      // console.log(profileData, '-----------jaimin')
      const tempProfileData = JSON.parse(localStorage.getItem('profile'));
      let data = {
        user_id: tempProfileData?.item?.user_id
      }
      let gameList = await getGamePlayed(data);
      // console.log(gameList?.data?.data)
      let payload = {
        games: [...gameList?.data?.data],
        appType: "webapp",
        user: profileData?.item?.user_id
      };
      const res = await getStatistics(payload);
      func("statistics", res?.data);
      setStatisticsVal(res?.data?.list);
    } catch (error) {
      func("error", error?.response?.data?.errors?.[0]?.msg);
    }
  };
  const handlerSelect = (e) => {
    func("college value", e);
    setCollegeValue(e?._id);
  };
  const handlerSubmit = async (e) => {
    if (collegeValue.length <= 0) {
      toast.error("Select College");
    } else {
      let payload = {
        college: collegeValue,
      };
      try {
        const res = await requestUpdateProfile(payload);
        if (res) {
          window.location.href = "/" + routes.home + "/" + routes.myProfile;
        }
      } catch (error) {
        if (
          error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
          error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
        )
          logout();
        toast.error(error?.response?.data?.errors[0]?.msg);
        func("erro", error?.response?.data?.errors[0]?.msg);
      }
    }
  };
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected
        ? "var(--secondary-color-emphasis)!important"
        : "var(--secondary-color-emphasis)!important",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "var(--secondary-color-emphasis)",
      padding: "8px",
      border: "none",
      boxShadow: "none",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      marginRight: "5px",
      padding: "6px",
    }),
    indicatorsSeparator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "none",
    }),
    indicatorContainer: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      backgroundColor: "red",
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "30px",
    }),
  };
  const getSeachCollegeList = async (searchValue) => {
    try {
      let payload = {
        sort: "desc",
        skip: collegeListData?.length,
        limit: 10,
        sortBy: "createdAt",
        q: searchValue,
      };
      const res = await collegeList(payload);
      setCollegeList([...res?.data?.list]);
      func("college list", res?.data);
    } catch (error) {
      func("error", error);
    }
  };
  const handlerViewInsights = (gameId, gameName) => {
    if (premiumUser) {
      func("gamre id 166", gameId);
      setgameId(gameId);
      setGameName(gameName);
      setShowNonSubscribeInsights(false);

      setShowInsights(true);
    } else {
      setShowInsights(false);
      setShowNonSubscribeInsights(true);
    }
  };

  useEffect(() => {
    // setLoading(true)
    // fetchAccountProfile();
    if (!premiumUser)
      setShowNonSubscribeInsights(true);

    let currentLevelPoints =
      userData?.item?.level?.level?.endPoint -
      (userData?.item?.level?.level?.startPoint - 1);

    let userPointsAsPerLevel =
      userData?.item?.level?.points -
      (userData?.item?.level?.level?.startPoint - 1);

    let lpVal = (userPointsAsPerLevel * 100) / currentLevelPoints;
    setLevelProgressBar(lpVal);
    getStatisticsList();
    // getCollegeList();
  }, []);
  useEffect(() => {
    fetchAccountProfile();
  }, [showEditProfile]);

  useEffect(() => {
    // setCollegeList([]);
    // getSeachCollegeList(searchValue);
  }, [searchValue]);

  const unSubscribe = async () => {
    // toast.error("UnSubscribed Not Impliment on frontend Ooredoo Redirction link required...");
    setLoading(true)
    try {
      let profile=JSON.parse(localStorage.getItem('profile'))
      if(profile?.item?.phone && profile?.item?.phoneCode){
        //console.log(">>>>>>>>>>>>>>",profile.item.phone, profile.item.phoneCode)
        let msisdn = profile?.item?.phoneCode?.replace("+", "") + profile?.item?.phone;
        let payload={
          "msisdn": msisdn
        }
        const data = await unSubscription(payload);
        console.log(">>>>>>>>>>>>>>>>>response::unsubscribe",data)
        if(data?.data?.success === true) {
          setLoading(false);
          toast.success(data.data.message);
          setTimeout(() => {
            window.location.href = "/home"
          }, 3000);
        } else {
          setLoading(false);
          toast.error(data.parms?.message);
        }
      }else{
        toast.error("Unsubscibe Failed, Can't find phone and phoneCode");
      }
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      toast.error(error.response?.data?.message);
    }
  }

  if (loading) return <Loader />;
  return (
    <>
      {/* <ToastContainer/> */}
      <div class="content">
        <div className="content-inner">
          <div class="row gy-4">
            <div class="col-12">
              <div class="profileBannerCard card">
                <div class="card-img position-relative">
                  <img src={ProfileBG} class="rounded10" alt="profile-bg" />
                </div>
                <div class="card-body">
                  <div class="card-content d-flex justify-content-center">
                    <div class="profilecard p-1">
                      <div class="row px-2">
                        <div class="row" >
                          <div class="col-12 d-flex flex-column justify-content-center align-items-center" >
                            <div class="row">
                              <div class="col-10 d-flex flex-column justify-content-center align-items-center" style={{paddingTop:'4rem'}} >
                                <h4 class="profiletitle " style={{fontSize:'2rem', fontWeight:'600'}}>
                                  {userData?.item?.gamerjiName}{" "}

                                </h4>
                                <h5 class="profiletitle" >
                                {userData?.item?.phoneCode}{" "}{userData?.item?.phone}{" "}
                                </h5>
                              </div>
                              <div class="col-2"  style={{paddingTop:'1rem',paddingLeft:'1rem'}}>
                                <div style={{ cursor: "pointer" }}>
                                  <span class="nav-link d-inline-block" onClick={() => {setEditProfile(true); eventTracking(events.profile_edit)}}>
                                    <span class="nav-link-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewbox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M3.32604 14.0439L2.23104 16.9639C2.189 17.0763 2.18018 17.1984 2.20565 17.3156C2.23113 17.4329 2.28981 17.5403 2.37469 17.6251C2.45958 17.7099 2.56708 17.7685 2.68435 17.7938C2.80162 17.8192 2.92371 17.8102 3.03604 17.7681L5.95521 16.6731C6.28933 16.5479 6.59279 16.3527 6.84521 16.1006L15.301 7.64474C15.301 7.64474 15.006 6.76058 14.1227 5.87641C13.2394 4.99308 12.3544 4.69808 12.3544 4.69808L3.89854 13.1539C3.64639 13.4063 3.45118 13.7098 3.32604 14.0439ZM13.5335 3.51891L14.686 2.36641C14.8927 2.15974 15.1685 2.02808 15.4569 2.07641C15.8627 2.14308 16.4835 2.34474 17.0685 2.93058C17.6544 3.51641 17.856 4.13641 17.9227 4.54224C17.971 4.83058 17.8394 5.10641 17.6327 5.31308L16.4794 6.46558C16.4794 6.46558 16.1852 5.58224 15.301 4.69891C14.4177 3.81391 13.5335 3.51891 13.5335 3.51891Z"
                                          fill="white"
                                        ></path>
                                      </svg>
                                    </span>
                                  </span>

                                </div>
                              </div>


                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-5 pt-lg-4 commonTab tournamentTypeTab">
            <div class="row gx-3 gy-5">
              {/* <div class="col-lg-7"> */}
              <div class="pe-lg-3 mt-2">
                <div class="col-12 commonTab profileTypeTab">
                  {/* <div class="col"><h2 class="h3 bodyExtraBoldFont mb-4">{t("Profile.statistic")}</h2></div> */}
                  {/* <ul
                    class="nav nav-tabs row"
                    id="profileTypeTab"
                    role="tablist"
                  >
                    <li class="nav-item col d-grid" role="presentation">
                      <button
                        class="nav-link active"
                        id="statistics-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#statistics-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="statistics-tab-pane"
                        aria-selected="true"
                      >
                        {t("Profile.statistic")}
                      </button>
                    </li>
                  <li class="nav-item col d-grid" role="presentation">
                      <button
                        class="nav-link"
                        id="collegiate-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#collegiate-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="collegiate-tab-pane"
                        aria-selected="false"
                      >
                        {t('Profile.collegiate')}
                      </button>
                    </li>
                  </ul> */}
                  {/* <div class="tab-content" id="earncoinTypeTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="statistics-tab-pane"
                      role="tabpanel"
                      aria-labelledby="statistics-tab"
                      tabindex="0"
                    >
                      <div class="row gx-3 gy-5">
                        {console.log("statisticsVal", statisticsVal)}
                        {statisticsVal?.length > 0 ? (
                          statisticsVal?.map((ele, ind) => (
                            <div className="col-12">
                              <div
                                class="card statisticsCard text-center"
                                key={ind}
                              >
                                <div class="card-header stats-header py-3 d-flex align-items-center flex-row justify-content-between">
                                  <h6 class="card-text mb-0 text-white">
                                    {ele?.game?.name}:{" "}
                                    <span class="text-purple">
                                      {userData?.item?.gamerjiName}
                                    </span>
                                  </h6>
                                  <h6 class="card-text mb-0 text-white">
                                    {t("Profile.rank")}:{" "}
                                    <span class="text-purple">
                                      {ele?.avgRates}
                                    </span>
                                  </h6>
                                  <span
                                    class="bodyBoldFont d11-insights-color"
                                    onClick={() => {
                                      eventTracking(events.View_Insights);
                                      console.log(ele?.game)
                                      handlerViewInsights(
                                        ele?.game?.saasGameId,
                                        ele?.game?.name
                                      )
                                    }
                                    }
                                  >
                                    {t("Profile.view_insights")}
                                  </span>
                                </div>
                                <div className="p-2 stats-chart">
                                  <p className="mb-0 text-white d-flex py-3 p-2"> {t('home.played')} :{ele?.played} </p>
                                
                                  <ResponsiveContainer width="100%" aspect={3}>
                                    <AreaChart
                                      width={730}
                                      height={250}
                                      data={ele.stateatistic}
                                      margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                      }}
                                    >
                                      <defs>
                                        <linearGradient
                                          id="colorUv"
                                          x1="0"
                                          y1="0"
                                          x2="0"
                                          y2="1"
                                        >
                                          <stop stopColor={colors[2]} />
                                          <stop
                                            offset="1"
                                            stopColor={colors[2]}
                                            stopOpacity={0}
                                          />
                                        </linearGradient>
                                      </defs>
                                      <XAxis dataKey="date" />
                                      <YAxis />
                                      <Tooltip />
                                      <Area
                                        type="monotone"
                                        dataKey="played"
                                        stroke={colors[0]}
                                        fillOpacity={1}
                                        fill="linear-gradient(181deg, #509E2F 100%, #509E2F 0%)"
                                      />
                                    </AreaChart>
                                  </ResponsiveContainer>

                                </div>

                              </div>
                            </div>
                          ))
                        ) : (
                          <h4>{t("notFound.no_records_found")}</h4>
                        )}
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="collegiate-tab-pane"
                      role="tabpanel"
                      aria-labelledby="collegiate-tab"
                      tabindex="0"
                    >
                      <div class="row gx-3">
                        <div class="row justify-content-center">
                          <div class="col-12">
                            {userData?.item?.collegeDetails ? (
                              <div class="card collegiateCard">
                                <div
                                  class="card-body"
                                  style={{ paddingTop: "3rem" }}
                                >
                                  <CollegeCard
                                    collegeDetails={
                                      userData?.item?.collegeDetails
                                    }
                                    sethowCollegeDetails={
                                      sethowCollegeDetails
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="col-6 md-12 col-sm-6">
                                <Select
                                  styles={customStyles}
                                  name="countryOption"
                                  // value={countryCode}
                                  onMenuScrollToBottom={(e) => {
                                    getCollegeList();
                                  }}
                                  onInputChange={(e) => {
                                    setSearchValue(e);
                                    getCollegeList();
                                  }}
                                  options={collegeListData}
                                  onChange={(e) => handlerSelect(e)}
                                  getOptionLabel={(ele) => (
                                    <div
                                      className="d11-menu"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {ele?.name}
                                    </div>
                                  )}
                                />
                                <div className="col-12 d-grid mt-5">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-arrow"
                                    onClick={(e) => handlerSubmit(e)}
                                  >
                                    {t("crm.submit")}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {isUserSubscribed && <div class="d-flex me-2"><button class="btn d11-premium-button  px-5" onClick={() => unSubscribe()}>{t('Profile.unsubscribe')}</button></div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLevel && (
        <LevelsModal showLevel={showLevel} setShowLevel={setShowLevel} />
      )}
      {showEditProfile && (
        <EditProfile
          showEditProfile={showEditProfile}
          setEditProfile={setEditProfile}
          userData={userData}
        />
      )}
      {showCollegeDetails && (
        <CollegeModal
          collegeDetails={userData?.item?.collegeDetails}
          showCollegeDetails={showCollegeDetails}
          sethowCollegeDetails={sethowCollegeDetails}
        />
      )}
      {showInsights && (
        <GameInsightsChart
          showInsights={showInsights}
          setShowInsights={setShowInsights}
          gameId={gameId}
          gamename={gameName}
        />
      )}
      {showNonSubscribeInsights && (
        <GameInsights
          showModal={showNonSubscribeInsights}
          setShowModal={setShowNonSubscribeInsights}
        />
      )}
    </>
  );
};

export default MyProfile;
